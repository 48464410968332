import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { useMouseMove, MyLottiePlayer, UseDragToScroll, GlobalContext } from "dir";
import circleAnimation from "images/circleAnimation9.json";

const cursorW = 45;
const cursorH = 45;
const biggerCursorW = 85;
const biggerCursorH = 85;

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const StyledMouseCenter = styled.div`
    ${({ styledCursor, ...props }) => {
        return css`
            z-index: 100000;
            position: fixed;
            width: ${styledCursor ? cursorW / 1.5 + "px" : cursorW + "px"};
            height: ${styledCursor ? cursorH / 1.5 + "px" : cursorH + "px"};
            pointer-events: none;
        `;
    }}
`;
const StyledMouseCircle = styled.div`
    ${({ styledCursor, ...props }) => {
        return css`
            z-index: 100000;
            position: fixed;
            width: ${styledCursor ? biggerCursorW + "px" : cursorW + "px"};
            height: ${styledCursor ? biggerCursorH + "px" : cursorH + "px"};
            /* transition: all 1000ms cubic-bezier(0.98, -0.49, 0.01, 1.45); */
            transition: all 250ms ease-out;
            pointer-events: none;
            opacity: 0.5;
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const CustomMouseCursor = () => {
    const [mouseX, mouseY] = useMouseMove();
    const { isSafeSize } = useContext(GlobalContext);

    if (!isSafeSize) return null;
    return (
        <React.Fragment>
            <UseDragToScroll />
            <MouseCircle mouseX={mouseX} mouseY={mouseY} />
            <MouseCenter mouseX={mouseX} mouseY={mouseY} />
        </React.Fragment>
    );
};

const MouseCenter = ({ mouseX, mouseY, ...props }) => {
    const { styledCursor } = useContext(GlobalContext);
    return (
        <StyledMouseCenter
            styledCursor={styledCursor.current}
            style={{
                top: styledCursor.current
                    ? mouseY - cursorH / 4 + "px"
                    : mouseY - cursorH / 2 + "px",
                left: styledCursor.current
                    ? mouseX - cursorW / 4 + "px"
                    : mouseX - cursorW / 2 + "px",
            }}
        >
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                width={styledCursor.current ? cursorW / 2 : cursorW}
                viewBox="0 0 35 35"
                space="preserve"
            >
                {styledCursor.current === "dragToScroll" ? (
                    <React.Fragment>
                        <path
                            style={{ fill: "#ffffff" }}
                            d="M8.56,10.4a1.12,1.12,0,0,1,0,1.57L3.79,16.73H14.43a1.11,1.11,0,0,1,0,2.22H3.79l4.77,4.77a1.12,1.12,0,0,1,0,1.57,1.13,1.13,0,0,1-1.58,0L.32,18.63h0a1.11,1.11,0,0,1,0-1.57h0L7,10.4A1.11,1.11,0,0,1,8.56,10.4Zm17.88,0a1.12,1.12,0,0,0,0,1.57l4.77,4.76H20.57a1.11,1.11,0,0,0,0,2.22H31.21l-4.77,4.77a1.12,1.12,0,0,0,0,1.57,1.13,1.13,0,0,0,1.58,0l6.66-6.66h0a1.11,1.11,0,0,0,0-1.57h0L28,10.4A1.11,1.11,0,0,0,26.44,10.4Z"
                        />
                        <path
                            style={{ fill: "#454545" }}
                            d="M8.56,9.71a1.12,1.12,0,0,1,0,1.57L3.79,16H14.43a1.11,1.11,0,0,1,0,2.22H3.79L8.56,23A1.11,1.11,0,1,1,7,24.6L.32,17.94h0a1.11,1.11,0,0,1,0-1.57h0L7,9.71A1.12,1.12,0,0,1,8.56,9.71Zm17.88,0a1.12,1.12,0,0,0,0,1.57L31.21,16H20.57a1.11,1.11,0,1,0,0,2.22H31.21L26.44,23A1.11,1.11,0,0,0,28,24.6l6.66-6.66h0a1.11,1.11,0,0,0,0-1.57h0L28,9.71A1.12,1.12,0,0,0,26.44,9.71Z"
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <path
                            style={{ fill: "#E62B32" }}
                            d="M18.68,16.72c0.19-0.15,0.46-0.16,0.66-0.04l14,8.52c0.13,0.08,0.22,0.2,0.26,0.34
	c0.09,0.31-0.09,0.64-0.4,0.73l-5.87,1.67l-3.1,5.25c-0.08,0.13-0.2,0.22-0.34,0.27c-0.31,0.09-0.64-0.08-0.73-0.4l-4.68-15.7
	C18.4,17.12,18.48,16.87,18.68,16.72L18.68,16.72z"
                        />
                        <path
                            style={{ fill: "#ffffff" }}
                            d="M23.72,34.08c-0.51,0-0.99-0.33-1.14-0.85l-4.68-15.7c-0.14-0.47,0.03-0.98,0.41-1.28l0,0
	c0.39-0.3,0.93-0.33,1.34-0.07l14,8.52c0.26,0.16,0.44,0.4,0.53,0.69c0.09,0.31,0.05,0.63-0.1,0.9c-0.15,0.28-0.41,0.48-0.71,0.57
	l-5.64,1.6l-2.98,5.04c-0.15,0.26-0.39,0.45-0.68,0.54C23.94,34.06,23.83,34.08,23.72,34.08z M19.06,17.21l4.67,15.67l3.2-5.46
	l6.1-1.73L19.06,17.21z"
                        />
                    </React.Fragment>
                )}
            </svg>
        </StyledMouseCenter>
    );
};

const MouseCircle = ({ mouseX, mouseY, ...props }) => {
    const { styledCursor } = useContext(GlobalContext);

    return (
        <StyledMouseCircle
            styledCursor={styledCursor.current}
            style={{
                top: styledCursor.current
                    ? mouseY - biggerCursorH / 2 + "px"
                    : mouseY - cursorH / 2 + "px",
                left: styledCursor.current
                    ? mouseX - biggerCursorW / 2 + "px"
                    : mouseX - cursorW / 2 + "px",
            }}
        >
            {styledCursor.current === "circle" ? (
                <MyLottiePlayer
                    name="circleAnimation"
                    width={biggerCursorW + "px"}
                    src={circleAnimation}
                    autoplay={true}
                    loop={false}
                    speed="2"
                />
            ) : styledCursor.current === "dragToScroll" ? null : (
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    width={cursorW}
                    viewBox="0 0 55 55"
                    space="preserve"
                >
                    <circle
                        style={{ fill: "none", stroke: "#707070" }}
                        cx="27.5"
                        cy="27.5"
                        r="16"
                    />
                    <circle
                        style={{ fill: "none", stroke: "#ffffff" }}
                        cx="27.5"
                        cy="27.5"
                        r="17"
                    />
                </svg>
            )}
        </StyledMouseCircle>
    );
};
