import { useContext } from "react";
import { GlobalContext } from "dir";
import styled, { css } from "styled-components";
import React from "react";
import { viaTheme, ScrollAnimation, Text, Bubbles, Button } from "dir";

import lightGrayBackground from "images/lightGrayBackground.jpg";
import beforeParalax from "images/beforeParalax.png";
import afterParalax from "images/afterParalax.png";
import beforeParalaxMobile from "images/beforeParalaxMobile.png";
import afterParalaxMobile from "images/afterParalaxMobile.png";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const StyledContainer = styled.div`
    ${(props) => {
        return css`
            position: relative;
            display: flex;
            flex-direction: ${props.isSafeSize ? "row" : "column"};
            flex-wrap: nowrap;
            max-height: ${props.isSafeSize ? "100vh" : false};
            max-width: ${props.isSafeSize ? false : "100vw"};
            margin-top: ${props.isSafeSize ? false : "-150px"};
        `;
    }}
`;
const CoverAnimation = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            position: relative;
            min-width: 100vw;
            min-height: 100vh;
            display: flex;
            flex-direction: ${props.isSafeSize ? "row" : "column"};
            box-sizing: border-box;
            padding-top: 150px;
            ${viaTheme(props, "padding-left", [20, 40, 80, 100, 200])};

            background: transparent url(${lightGrayBackground}) no-repeat right center;
            background-size: cover;

            & > div[aria-label="infoZone"] {
                ${viaTheme(props, "width", ["90vw", "80vw", "80vw", "400px", "500px"], "")};
            }
        `;
    }}
`;
const ParalaxImage = styled.div`
    ${(props) => {
        return css`
            z-index: 1;
            position: fixed;
            left: 0px;
            top: 0px;
            min-width: 100vw;
            min-height: 100vh;
            background: transparent
                url(${require("pages/Works/" + props.folder + "/02_paralaxImage.jpg").default})
                no-repeat center center;
            background-size: cover;
        `;
    }}
`;
const PartBeforeParalaxImage = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            position: relative;
            width: ${props.isSafeSize ? "200px" : "100vw"};
            height: ${props.isSafeSize ? "100%" : "200px"};

            background: transparent url(${props.isSafeSize ? beforeParalax : beforeParalaxMobile})
                no-repeat center top;
            background-size: cover;
        `;
    }}
`;
const PartAfterParalaxImage = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            position: relative;
            margin-left: ${props.isSafeSize ? "50vw" : false};
            margin-top: ${props.isSafeSize ? false : "300px"};
            width: ${props.isSafeSize ? "200px" : "100vw"};
            height: ${props.isSafeSize ? "100%" : "100px"};

            background: transparent url(${props.isSafeSize ? afterParalax : afterParalaxMobile})
                no-repeat center top;
            background-size: cover;
        `;
    }}
`;

const HiddenImage = styled.img`
    ${(props) => {
        return css`
            width: 1px;
            height: 1px;
            opacity: 0;
            display: none;
            position: absolute;
        `;
    }}
`;

const ImgZone = styled.div`
    ${(props) => {
        return css`
            display: flex;
            position: relative;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
        `;
    }}
`;
const MouseMoveImage = styled.img`
    ${(props) => {
        return css`
            position: relative;
            width: 400px;
            height: 400px;
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const WorksCover = ({ projectDetails, ...props }) => {
    const { isSafeSize, goTo } = useContext(GlobalContext);

    /**
     * Return
     */
    return (
        <StyledContainer
            aria-label="StyledContainer"
            style={{ paddingLeft: 0 }}
            isSafeSize={isSafeSize}
        >
            <HiddenImage
                src={
                    require("pages/Works/" + projectDetails.folder + "/02_paralaxImage.jpg").default
                }
                alt=""
            />
            <HiddenImage src={lightGrayBackground} alt="" />
            <HiddenImage src={beforeParalax} alt="" />
            <HiddenImage src={afterParalax} alt="" />
            <CoverAnimation aria-label="CoverAnimation" isSafeSize={isSafeSize}>
                <div aria-label="infoZone">
                    <ScrollAnimation scrollFromX={isSafeSize ? true : false} cssClassName="fadeIn">
                        <Text pattern="seperator">{projectDetails.project}</Text>
                        {projectDetails.keywords}
                        <br />
                        <Text>{projectDetails.desc}</Text>
                        <br />
                        {!projectDetails.arrUX ? null : (
                            <Bubbles backgroundColor="#E8E2D5" color="#454545">
                                {projectDetails.arrUX}
                            </Bubbles>
                        )}
                        {!projectDetails.arrUI ? null : (
                            <Bubbles backgroundColor="#E6D6D9" color="#454545">
                                {projectDetails.arrUI}
                            </Bubbles>
                        )}
                        {!projectDetails.arrFE ? null : (
                            <Bubbles backgroundColor="#D6DDDF" color="#454545">
                                {projectDetails.arrFE}
                            </Bubbles>
                        )}
                        {!projectDetails.arrPRI ? null : (
                            <Bubbles backgroundColor="#cec7de" color="#454545">
                                {projectDetails.arrPRI}
                            </Bubbles>
                        )}
                        <br />
                        <br />
                        <Button onClick={() => goTo("Sneakers")}>Next project</Button>
                    </ScrollAnimation>
                </div>
                <ImgZone>
                    <MouseMoveImage
                        src={
                            require("pages/Works/" + projectDetails.folder + "/03_coverImage.png")
                                .default
                        }
                        alt=""
                    />
                </ImgZone>
            </CoverAnimation>
            <PartBeforeParalaxImage
                aria-label="PartBeforeParalaxImage"
                isSafeSize={isSafeSize}
            ></PartBeforeParalaxImage>
            <ParalaxImage aria-label="ParalaxImage" folder={projectDetails.folder}></ParalaxImage>
            <PartAfterParalaxImage
                aria-label="PartAfterParalaxImage"
                isSafeSize={isSafeSize}
            ></PartAfterParalaxImage>
        </StyledContainer>
    );
};
