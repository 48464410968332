import { useRef, useState, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import { viaTheme, Text, GlobalContext } from "dir";
import useEventListener from "@use-it/event-listener";

//-------------------------------------------------------------
// 		SAMPLES
//-------------------------------------------------------------

/*


                <OnlyTitle>About Project</OnlyTitle>

                <OnlyText>
                    The brand sales popular brands’ sport-shoes, clothing and relevant equipment.
                    Due to its strategy, they concentrate on basketball and sneakers culture. In the
                    city’s 15 popular location, they have branches and sale point. The products they
                    sell are luxury and expensive therefore the target audience is men-women who
                    mostly white-collar, high-income, 25-35 aged, loves USA culture and brands.
                    Also, they have an online e-commerce platform that they want to improve. By this
                    way, they want to widen the area they can reach.
                </OnlyText>

                <TextAndTitle title="About Project">
                    The brand sales popular brands’ sport-shoes, clothing and relevant equipment.
                    Due to its strategy, they concentrate on basketball and sneakers culture. In the
                    city’s 15 popular location, they have branches and sale point. The products they
                    sell are luxury and expensive therefore the target audience is men-women who
                    mostly white-collar, high-income, 25-35 aged, loves USA culture and brands.
                    Also, they have an online e-commerce platform that they want to improve. By this
                    way, they want to widen the area they can reach.
                </TextAndTitle>

                <MultiImage
                    folder={projectDetails.folder}
                    arr={[
                        { path: "im01.jpg", width: 300 },
                        { path: "im02.jpg", width: 300 },
                    ]}
                />

                <SingleLongImage
                    folder={projectDetails.folder}
                    arr={{ path: "im03.jpg", width: 800 }}
                />

*/
//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const Container = styled.div`
    ${(props) => {
        return css`
            background-color: white;
            z-index: 3;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding-top: ${props.isSafeSize ? "150px" : "20px"};
            box-sizing: border-box;
            ${viaTheme(props, "padding-left", [20, 30, 40, 50, 50])};
            ${viaTheme(props, "width", ["100vw", "100vw", "100vw", 500, 500], "")};

            padding-right: 150px;
        `;
    }}
`;
const Container2 = styled(Container)`
    ${(props) => {
        return css`
            padding-top: ${props.isSafeSize ? "213px" : "20px"};
        `;
    }}
`;
const ImgDiv = styled.div`
    ${(props) => {
        return css`
            background-color: white;
            z-index: 3;
            display: flex;
            flex-direction: ${props.horizontal ? "row" : "column"};
            justify-content: flex-start;
            align-items: flex-start;
            padding: ${props.isSafeSize ? "240px 30px" : "30px"};
            box-sizing: border-box;
            padding-right: 150px;
            pointer-events: none;
        `;
    }}
`;
const StyledIm = styled.img`
    ${(props) => {
        return css`
            margin: 30px;
            margin-top: 0px;
            ${viaTheme(props, "width", [300, 300, "70vw", props.width, props.width], "px")};
            pointer-events: none;
        `;
    }}
`;

//-------------------------------------------------------------
// 		ONLY TITLE
//-------------------------------------------------------------

export const OnlyTitle = (props) => {
    const { isSafeSize } = useContext(GlobalContext);
    return (
        <Container isSafeSize={isSafeSize}>
            <Text pattern="seperator">{props.children}</Text>
        </Container>
    );
};

//-------------------------------------------------------------
// 		ONLY TEXT
//-------------------------------------------------------------

export const OnlyText = (props) => {
    const { isSafeSize } = useContext(GlobalContext);
    return (
        <Container2 isSafeSize={isSafeSize}>
            <Text>{props.children}</Text>
        </Container2>
    );
};

//-------------------------------------------------------------
// 		TextAndTitle
//-------------------------------------------------------------

export const TextAndTitle = (props) => {
    const { isSafeSize } = useContext(GlobalContext);
    return (
        <Container isSafeSize={isSafeSize}>
            <Text pattern="seperator">{props.title}</Text>
            <Text>{props.children}</Text>
        </Container>
    );
};

//-------------------------------------------------------------
// 		Multi Image Div
//-------------------------------------------------------------

export const MultiImage = ({ horizontal, folder, arr, ...props }) => {
    const { isSafeSize } = useContext(GlobalContext);
    return (
        <ImgDiv isSafeSize={isSafeSize} horizontal={horizontal}>
            {!arr
                ? null
                : arr.map((value, key) => {
                      return (
                          <StyledIm
                              key={key}
                              width={value.width}
                              src={require("pages/Works/" + folder + "/" + value.path).default}
                              alt=""
                          />
                      );
                  })}
        </ImgDiv>
    );
};

//-------------------------------------------------------------
// 		Single Long Image Div
//-------------------------------------------------------------

const LongImgDiv = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: ${props.isSafeSize ? "240px 30px" : false};
        `;
    }}
`;
const StickyDiv = styled.div`
    ${(props) => {
        return css`
            position: ${props.isSafeSize ? "sticky" : "relative"};
            left: ${props.isSafeSize ? props.stickyLeftValue + "px" : "30px"};
        `;
    }}
`;
const StyledLongIm = styled.img`
    ${(props) => {
        return css`
            margin-bottom: 20px;
            ${viaTheme(props, "width", [300, 300, "70vw", props.width, props.width], "px")};
        `;
    }}
`;

export const SingleLongImage = ({ folder, arr, ...props }) => {
    const { WINW, WINH, isSafeSize } = useContext(GlobalContext);
    const paddingTop = isSafeSize ? 240 : 30;
    const divZone = useRef();
    const image = useRef();
    const [marginTopValue, setMarginTopValue] = useState();
    const [imageRect, setImageRect] = useState();
    const [stickyLeftValue, setStickyLeftValue] = useState(400);

    /**
     * on load
     * Define Sticky Left Value
     */
    const defineStickyLeftValue = () => {
        if (!imageRect || !imageRect.width) return;
        setStickyLeftValue((WINW - imageRect.width) / 2);
    };
    useEffect(() => defineStickyLeftValue(), [imageRect, WINW, WINH]);

    /**
     * on scroll
     * Define New MarginTop
     */
    const defineMarginTopValue = () => {
        if (!imageRect || !imageRect.height || !isSafeSize) return;

        const divZoneRect = divZone.current.getBoundingClientRect();
        const maxMarginTop = imageRect.height + paddingTop * 2 - WINH;
        const valueMoveWillStart = stickyLeftValue + 30;
        const valueMoveWillEnd = -1 * (divZoneRect.width - WINW + stickyLeftValue - 30);

        let newMarginTop;
        if (divZoneRect.x > valueMoveWillStart) newMarginTop = 0;
        else if (divZoneRect.x < valueMoveWillEnd) newMarginTop = maxMarginTop;
        else {
            const unit = maxMarginTop / (-1 * valueMoveWillEnd + valueMoveWillStart);
            // console.log(unit);
            newMarginTop = -1 * (divZoneRect.x - valueMoveWillStart) * unit;
        }
        setMarginTopValue(newMarginTop);
    };
    useEventListener("scroll", defineMarginTopValue);
    useEffect(() => defineMarginTopValue(), [WINW, WINH, imageRect]);

    /**
     * Return
     */
    // console.log(newMarginTopValue.current);
    return (
        <LongImgDiv
            ref={divZone}
            isSafeSize={isSafeSize}
            style={
                isSafeSize
                    ? { width: imageRect?.height + paddingTop * 10 || 500 }
                    : { width: "100vw" }
            }
        >
            <StickyDiv
                aria-label="stckyZone"
                isSafeSize={isSafeSize}
                stickyLeftValue={stickyLeftValue}
                style={isSafeSize ? { marginTop: -1 * marginTopValue || 0 } : {}}
            >
                <StyledLongIm
                    ref={image}
                    onLoad={() => setImageRect(image.current.getBoundingClientRect())}
                    width={arr.width}
                    src={require("pages/Works/" + folder + "/" + arr.path).default}
                    alt=""
                />
            </StickyDiv>
        </LongImgDiv>
    );
};
