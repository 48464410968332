import { useContext } from "react";
import styled, { css } from "styled-components";
import { viaTheme, ContentDiv, Text, GlobalContext, MyLottiePlayer } from "dir";
import linkedInLogo from "images/linkedin.jpg";
import envelope from "images/envelope.jpg";
import contactEnd from "images/contactEnd.jpg";
import contactEndMobile from "images/contactEndMobile.jpg";
import seperatorText_contact from "images/seperatorText_contact.json";
import seperatorText_contact_hor from "images/seperatorText_contact_hor.json";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const StyledWelcome = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: 100%;
            padding-top: 150px;
            box-sizing: border-box;
            ${viaTheme(props, "margin-left", [20, 40, 80, 100, 200])};
            margin-bottom: ${props.isSafeSize ? "0px" : "100px"};

            width: ${props.isSafeSize ? "calc(100vw - 200px - 500px)" : "calc(100vw - 20px)"};

            /* ${viaTheme(props, "width", [300, 400, 500, 600, 800], "px")}; */
        `;
    }}
`;
const LinkedInLogo = styled.img`
    ${(props) => {
        return css`
            margin-right: 30px;
            width: 40px;
        `;
    }}
`;
const LinkedInLogoLink = styled.a`
    ${(props) => {
        return css`
            cursor: none;
        `;
    }}
`;
const AddressLines = styled.div`
    ${(props) => {
        return css`
            cursor: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const Contact = (props) => {
    const { styledCursor, WINW, WINH, isSafeSize } = useContext(GlobalContext);

    /**
     * Return
     */
    return (
        <ContentDiv label="contact">
            <MyLottiePlayer
                name="seperatorText_contact"
                width="500px"
                src={isSafeSize ? seperatorText_contact : seperatorText_contact_hor}
                scroll={true}
                scrollDirection={isSafeSize ? "left" : "top"}
                autoplay={false}
                loop={false}
                speed="1"
                scrollEnterExit={[0, 50]}
            />

            <StyledWelcome isSafeSize={isSafeSize}>
                <Text pattern="seperator">Get in touch</Text>
                <Text>
                    pxa creative is a London based, Personal Service Company, registered as a LTD
                    company in the UK. Please get in touch with <b>Baris Kuran</b> for any of your
                    question, project or contract.
                </Text>
                <AddressLines
                    onMouseOver={() => (styledCursor.current = "circle")}
                    onMouseOut={() => (styledCursor.current = false)}
                >
                    <LinkedInLogoLink
                        href="https://www.linkedin.com/in/bariskuran/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LinkedInLogo src={linkedInLogo} alt="" />
                    </LinkedInLogoLink>
                    <LinkedInLogoLink
                        href="https://www.linkedin.com/in/bariskuran/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Text>in/bariskuran</Text>
                    </LinkedInLogoLink>
                </AddressLines>
                <AddressLines
                    onMouseOver={() => (styledCursor.current = "circle")}
                    onMouseOut={() => (styledCursor.current = false)}
                >
                    <LinkedInLogoLink
                        href="mailto:baris@thepxa.co.uk"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LinkedInLogo src={envelope} alt="" />
                    </LinkedInLogoLink>
                    <LinkedInLogoLink
                        href="mailto:baris@thepxa.co.uk"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Text>baris@thepxa.co.uk</Text>
                    </LinkedInLogoLink>
                </AddressLines>
            </StyledWelcome>
            <img
                src={isSafeSize ? contactEnd : contactEndMobile}
                alt=""
                style={WINW > WINH ? { height: WINH + "px" } : { width: WINW + "px" }}
            />
        </ContentDiv>
    );
};
