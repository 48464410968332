import { useContext, useState, useLayoutEffect } from "react";
import styled, { css } from "styled-components";
import {
    MyStickyLottiePlayer,
    viaTheme,
    ContentDiv,
    GlobalContext,
    Text,
    Button,
    HotAirBalloon2,
    ScrollAnimation,
} from "dir";
import welcomeSpace from "images/welcomeSpace.json";
import spaceBackground from "images/space_back.jpg";
import spaceBackgroundMobile from "images/space_backMobile.jpg";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const StyledWelcome = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: ${props.isSafeSize ? "100%" : false};
            padding-top: ${props.isSafeSize ? "150px" : "0px"};
            box-sizing: border-box;

            ${viaTheme(props, "width", [300, 400, 500, 600, 700], "px")};
        `;
    }}
`;
const StyledWelcomeLightZone = styled.div`
    ${(props) => {
        return css`
            z-index: 2;
            position: sticky;
            left: ${props.isSafeSize ? "0px" : false};
            top: ${props.isSafeSize ? false : "0px"};
            /* background-color: red; */

            width: ${props.isSafeSize ? props.expectedWidth : "50px"};
            height: ${props.expectedWidth};
            margin-top: ${props.marginTop};
            margin-left: ${props.marginLeft};
            pointer-events: none;
            /* this margin-left value is a sum of StyledWelcome's width + Main Layout > first div > padding left */
            /* ${viaTheme(props, "padding-left", [10, 20, 30, 100, 200])}; */
            ${!props.isSafeSize
                ? viaTheme(
                      props,
                      "margin-left",
                      [
                          -20 + props.marginLeft,
                          -40 + props.marginLeft,
                          -80 + props.marginLeft,
                          -100 + props.marginLeft,
                          -200 + props.marginLeft,
                      ],
                      "px",
                  )
                : viaTheme(
                      props,
                      "margin-left",
                      [
                          -310 + props.marginLeft,
                          -420 + props.marginLeft,
                          -530 + props.marginLeft,
                          -700 + props.marginLeft,
                          -900 + props.marginLeft,
                      ],
                      "px",
                  )};
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const Welcome = (props) => {
    const { WINW, WINH, isSafeSize } = useContext(GlobalContext);
    const [currentMarginTop, setCurrentMarginTop] = useState(0);
    const [currentMarginLeft, setCurrentMarginLeft] = useState(0);
    const [expectedWidth, setExpectedWidth] = useState();
    const [backImageWidth, setBackImageWidth] = useState();
    const backImageDimension = 35992 / 2000;

    /**
     * Calculate Expected Width
     */
    useLayoutEffect(() => {
        let newLeft = 0;
        let newTop = 0;
        let newWidth = 0;
        let newBackImageWidth = 0;

        const calculateExpectedWidth = () => {
            if (WINW > WINH) {
                newWidth = WINW;
                newTop = (WINH - WINW) / 2;
                newBackImageWidth = backImageDimension * WINH;
            } else {
                newWidth = WINH;
                newLeft = (WINW - WINH) / 2;
                newBackImageWidth = backImageDimension * WINW;
            }
        };
        calculateExpectedWidth();

        setCurrentMarginTop(newTop);
        setCurrentMarginLeft(newLeft);
        setExpectedWidth(newWidth);
        setBackImageWidth(newBackImageWidth);
    }, [WINW, WINH]);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <ContentDiv
            label="welcome"
            width={backImageWidth + "px"}
            backImage={isSafeSize ? spaceBackground : spaceBackgroundMobile}
        >
            <StyledWelcome isSafeSize={isSafeSize}>
                <Text pattern="seperator">A one-man band located in London, focused on</Text>
                <ScrollAnimation
                    scrollFromX={isSafeSize ? true : false}
                    cssClassName="textEffect_comeFromUp"
                >
                    <Text pattern="seperator">UXUI design and</Text>
                </ScrollAnimation>
                <ScrollAnimation
                    scrollFromX={isSafeSize ? true : false}
                    cssClassName="textEffect_comeFromUp250"
                >
                    <Text pattern="seperator">FE Development.</Text>
                </ScrollAnimation>

                <Text>
                    End to end team player, hardworking and extremely creative... Yep, that’s me:
                    <br />
                    Baris Kuran.
                </Text>
                <Button onClick={() => window.scrollTo(document.body.scrollWidth, 0)}>
                    Get in touch
                </Button>
            </StyledWelcome>
            <HotAirBalloon2 />
            <StyledWelcomeLightZone
                isSafeSize={isSafeSize}
                expectedWidth={expectedWidth + "px"}
                marginTop={currentMarginTop + "px"}
                marginLeft={currentMarginLeft}
                aria-label="styledWelcomeLightZone"
            >
                <MyStickyLottiePlayer
                    name="welcomeSpace"
                    width={expectedWidth + "px"}
                    src={welcomeSpace}
                    speed="1"
                    scrollDirection={isSafeSize ? "left" : "top"}
                    scrollValueToStart={0}
                    scrollValueToEnd={backImageWidth}
                />
            </StyledWelcomeLightZone>
        </ContentDiv>
    );
};
