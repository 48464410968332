import { useRef, useState, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import useEventListener from "@use-it/event-listener";
import {
    useScrollMove,
    Icon,
    Hamburger,
    HomeIcon,
    useScrollHeight,
    theme,
    GlobalContext,
} from "dir";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const PXAScrollBar = styled.div`
    ${(props) => {
        return css`
            position: fixed;
            z-index: 1000;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            bottom: 15px;
            left: 3%;
            width: 94%;
            height: 50px;
        `;
    }}
`;
const HamburgerIconArea = styled.div`
    ${(props) => {
        return css`
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
        `;
    }}
`;
const MenuArea = styled.div`
    ${({ isHoverActive, ...props }) => {
        return css`
            position: relative;
            display: flex;
            align-items: center;
            background-color: ${props.theme.colors.softerGrey};
            transition: all 250ms ease;
            width: 100%;
            height: ${isHoverActive ? "50px" : "3px"};
            overflow: hidden;
        `;
    }}
`;
const StyledColoredDynamicArea = styled.div`
    ${(props) => {
        return css`
            position: absolute;
            z-index: 1;
            left: 0px;
            top: 0px;
            min-width: 50px;
            background-color: ${props.theme.colors.brand};
            height: 100%;
            transition: all 100ms ease;
        `;
    }}
`;
const StyledMenuContent = styled.div`
    ${({ isHoverActive, ...props }) => {
        return css`
            display: flex;
            z-index: 2;
            align-items: center;
            transition: all 250ms ease;
            width: 100%;
            height: ${isHoverActive ? "50px" : "3px"};
            opacity: ${isHoverActive ? 1 : 0};
            overflow: hidden;
            font-weight: 700;
            color: #999999;
            mix-blend-mode: luminosity;

            & > div[aria-label="HomeIcon"] {
                position: relative;
                width: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;

                & > div[aria-label="HomeIconLine"] {
                    background-color: white;
                    margin-left: 4px;
                    width: 1px;
                    height: 30px;
                }
            }
        `;
    }}
`;
const StyledSpan = styled.span`
    ${(props) => {
        return css`
            font-size: 13px;
            font-weight: 700;
            padding-left: 10px;
            padding-right: 10px;
            text-transform: uppercase;
            letter-spacing: 2px;
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const PxaScrollBar = ({ sizes, ...props }) => {
    const { WINW, WINH } = useContext(GlobalContext);
    const scrollBar = useRef();
    const [isHoverActive, setIsHoverActive] = useState(false);
    const menuArea = useRef();
    const [menuAreaWidth, setMenuAreaWidth] = useState(0);
    const menuAreaWidthMinusHomeIconW50 = menuAreaWidth - 50;
    const [, scrollLeft] = useScrollMove();

    /**
     * calculate menu area width
     */
    const calculateMenuAreaWidth = () => {
        if (!menuArea.current) return;

        let newWidth = Number(menuArea.current.getBoundingClientRect().width.toFixed(2));
        if (menuAreaWidth !== newWidth) setMenuAreaWidth(newWidth);
    };
    useEventListener("load", () => calculateMenuAreaWidth());
    useEffect(() => {
        calculateMenuAreaWidth();
    }, [WINW, WINH, sizes]);

    /*
     *   Catch Key Arrow Up and Down for scrolling
     */
    const catchKeys = (e) => {
        let jumpValue = 100;
        let eventKeyCode = Number(e.keyCode || window.event.keyCode);
        if (eventKeyCode === 38 || eventKeyCode === 37) {
            // UP - LEFt
            window.scrollTo({ top: 0, left: window.scrollX - jumpValue });
            e.preventDefault();
        } else if (eventKeyCode === 40 || eventKeyCode === 39 || eventKeyCode === 32) {
            // DOWN - RIGHT
            window.scrollTo({ top: 0, left: window.scrollX + jumpValue });
            e.preventDefault();
        }
    };
    useEventListener("keydown", (e) => catchKeys(e));

    /**
     * OnWheelHandler for Horizontal Scroll
     * This converts vertical scrolling to horizontal scrolling
     */
    const onWheelHandler = (e) => {
        window.scrollTo({ top: 0, left: window.scrollX + e.deltaY });
        e.preventDefault();
    };
    useEventListener("mousewheel", onWheelHandler, window, { passive: false });

    /**
     * Calculate total Width of incoming sections
     */
    const [totalSizesW, setTotalSizesW] = useState(0);
    useEffect(() => calculateTotalSizesW(), [WINW, WINH, sizes]);
    const calculateTotalSizesW = () => {
        let totalAmount = 0;
        sizes.forEach((el) => {
            totalAmount += el.w;
        });
        // console.log(WINW, sizes[3]?.w);
        setTotalSizesW(totalAmount);
    };

    return (
        <PXAScrollBar
            aria-label="PXAScrollBar"
            ref={scrollBar}
            onMouseEnter={() => setIsHoverActive(true)}
            onMouseLeave={() => setIsHoverActive(false)}
        >
            <HamburgerIconArea>
                <Icon
                    icon={Hamburger}
                    width="45"
                    color={theme.colors.softGrey}
                    hoverColor={theme.colors.softGrey}
                />
            </HamburgerIconArea>
            <MenuArea isHoverActive={isHoverActive} ref={menuArea}>
                <ColoredDynamicArea
                    menuAreaWidth={menuAreaWidth}
                    sizes={sizes}
                    menuAreaWidthMinusHomeIconW50={menuAreaWidthMinusHomeIconW50}
                    totalSizesW={totalSizesW}
                    scrollLeft={scrollLeft}
                />
                <MenuContent
                    aria-label="MenuContent"
                    isHoverActive={isHoverActive}
                    sizes={sizes}
                    menuAreaWidth={menuAreaWidth}
                    menuAreaWidthMinusHomeIconW50={menuAreaWidthMinusHomeIconW50}
                    totalSizesW={totalSizesW}
                    scrollLeft={scrollLeft}
                />
            </MenuArea>
        </PXAScrollBar>
    );
};

//-------------------------------------------------------------
// 		COLORED DYNAMIC AREA
//-------------------------------------------------------------

const ColoredDynamicArea = ({
    sizes,
    menuAreaWidth,
    menuAreaWidthMinusHomeIconW50,
    totalSizesW,
    scrollLeft,
    ...props
}) => {
    const { WINW } = useContext(GlobalContext);
    const [scrollWidth] = useScrollHeight();
    const minScroll = sizes[0]?.l + scrollLeft || 99;
    const width =
        ((menuAreaWidth - 50) / (scrollWidth - WINW - minScroll)) * (scrollLeft - minScroll) + 50;
    return <StyledColoredDynamicArea style={{ width: width || 0 }} />;
};

//-------------------------------------------------------------
// 		CLICABLE SECTION NAMES
//-------------------------------------------------------------

const MenuContent = ({
    sizes,
    menuAreaWidth,
    menuAreaWidthMinusHomeIconW50,
    totalSizesW,
    scrollLeft,
    ...props
}) => {
    const scrollTo = (where) => {
        window.scrollTo({
            left: where,
            behavior: "smooth",
        });
    };
    const { styledCursor, goTo } = useContext(GlobalContext);

    return (
        <StyledMenuContent aria-label="MenuContent" isHoverActive={props.isHoverActive}>
            <div
                aria-label="HomeIcon"
                onMouseOver={() => (styledCursor.current = "circle")}
                onMouseOut={() => (styledCursor.current = false)}
                onClick={() => goTo("")}
            >
                <Icon
                    icon={HomeIcon}
                    width="45"
                    color={theme.colors.white}
                    hoverColor={theme.colors.white}
                />
                <div aria-label="HomeIconLine" />
            </div>
            {sizes.map((title, key) => {
                if (totalSizesW === 0) return null;

                // const titleW =
                //     key === sizes.length - 1
                //         ? (menuAreaWidthMinusHomeIconW50 * (title.w - WINW)) / (totalSizesW - WINW)
                //         : (menuAreaWidthMinusHomeIconW50 * title.w) / (totalSizesW - WINW);

                const titleW2 = (menuAreaWidthMinusHomeIconW50 * title.w) / totalSizesW;
                return (
                    <div key={key} style={{ width: titleW2 }}>
                        <StyledSpan
                            onClick={(e) => {
                                scrollTo(title.l + scrollLeft);
                                e.stopPropagation();
                            }}
                            onMouseOver={() => (styledCursor.current = "circle")}
                            onMouseOut={() => (styledCursor.current = false)}
                        >
                            {title.name || "noName"}
                        </StyledSpan>
                    </div>
                );
            })}
        </StyledMenuContent>
    );
};
