import _ from "lodash";

export const pushArrayAsSorted = (arr = [], el = 0) => {
    let array2 = _.cloneDeep(arr);
    _.sortBy(array2);
    //
    let i = _.sortedIndex(array2, el);
    array2.splice(i, 0, el);
    return { arr: array2, i: i };
};
