import { useContext } from "react";
import styled, { css } from "styled-components";
import { GlobalContext, viaTheme } from "dir";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const StyledBody = styled.div`
    ${(props) => {
        return css`
            position: relative;
            display: inline-block;
            width: 100%;
            height: 100%;
            display: block;
            font-weight: 400;
            line-height: 2;
            max-width: ${props.isSafeSize ? false : "100vw"};
            overflow-x: ${props.isSafeSize ? false : "clip"};
            ${viaTheme(props, "font-size", [14, 14, 14, 15, 16])};
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const Body = (props) => {
    const { children = "" } = props;
    const { isSafeSize } = useContext(GlobalContext);

    return (
        <StyledBody aria-label="bodyDiv" isSafeSize={isSafeSize}>
            {children}
        </StyledBody>
    );
};
