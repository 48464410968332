import React from "react";
import styled, { css } from "styled-components";
import { findInSource, findColor, findUnit, findMargin } from "dir";
import PropTypes from "prop-types";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

// const defaultDiv = styled.div`
const defaultDiv = styled(
    ({
        tag = "div",
        children,
        pattern,
        display,
        position,
        color,
        backgroundColor,
        width,
        height,
        margin,
        padding,
        direction,
        wrap,
        flex,
        justify,
        align,
        placeholder,
        full,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        ...props
    }) => React.createElement(tag, props, children),
)`
    ${(props) => {
        return css`
            display: ${props.display || "flex"};
            position: ${props.position || "relative"};
            color: ${props.color || false};
            background-color: ${props.backgroundColor || false};
            width: ${props.width || "max-content"};
            height: ${props.height || false};
            min-width: ${props.minWidth || false};
            min-height: ${props.minHeight || false};
            max-width: ${props.maxWidth || false};
            max-height: ${props.maxHeight || false};
            margin: ${props.margin || "0px"};
            padding: ${props.padding || "0px"};
            flex-direction: ${props.direction || "row"};
            flex-wrap: ${props.wrap || "wrap"};
            flex: ${props.flex || "1 0 auto"};
            justify-content: ${props.justify || "center"};
            align-items: ${props.align || "center"};
        `;
    }}
`;

//-------------------------------------------------------------
// 		STYLES and PROP VALUES
//-------------------------------------------------------------

const patterns = {
    default: defaultDiv,
    full: styled(defaultDiv)`
        ${(props) => {
            return css`
                width: 100%;
                height: 100%;
            `;
        }}
    `,
    placeholder: styled(defaultDiv)`
        ${(props) => {
            return css`
                width: ${props.width || "100%"};
                height: ${props.height || "100%"};
                background-color: ${"rgba(" +
                Math.random() * 255 +
                "," +
                Math.random() * 255 +
                "," +
                Math.random() * 255 +
                ",1)"};
            `;
        }}
    `,
};
const directions = {
    row: "row",
    col: "column",
    column: "column",
    "column-reverse": "column-reverse",
    "row-reverse": "row-reverse",
};
const wraps = {
    nowrap: "nowrap",
    wrap: "wrap",
    "wrap-reverse": "wrap-reverse",
};
const positions = {
    static: "static",
    absolute: "absolute",
    fixed: "fixed",
    relative: "relative",
    sticky: "sticky",
};
const justifies = {
    "flex-start": "flex-start",
    "flex-end": "flex-end",
    center: "center",
    "space-between": "space-between",
    "space-around": "space-around",
};
const aligns = {
    "flex-start": "flex-start",
    "flex-end": "flex-end",
    center: "center",
    baseline: "baseline",
    stretch: "stretch",
};

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const Div = ({
    display,
    position,
    color,
    backgroundColor,
    width,
    height,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    margin,
    padding,
    direction,
    wrap,
    flex,
    full = false,
    justify,
    align,
    pattern,
    isLoading,
    children,
    innerRef,
    ...props
}) => {
    const Comp = findInSource(patterns, pattern, props);

    return (
        <Comp
            display={display}
            position={position}
            color={findColor(color)}
            backgroundColor={findColor(backgroundColor)}
            width={full ? "100%" : findUnit(width)}
            height={full ? "100%" : findUnit(height)}
            minWidth={findUnit(minWidth)}
            minHeight={findUnit(minHeight)}
            maxWidth={findUnit(maxWidth)}
            maxHeight={findUnit(maxHeight)}
            margin={findMargin(margin)}
            padding={findMargin(padding)}
            direction={findInSource(directions, direction)}
            wrap={findInSource(wraps, wrap)}
            flex={flex}
            justify={justify}
            align={align}
            {...props}
        >
            {children}
        </Comp>
    );
};

//-------------------------------------------------------------
// 		PROP TYPES
//-------------------------------------------------------------

Div.propTypes = {
    pattern: PropTypes.oneOf(Object.keys(patterns)),
    display: PropTypes.string,
    position: PropTypes.oneOf(Object.keys(positions)),
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    minWidth: PropTypes.string,
    minHeight: PropTypes.string,
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
    direction: PropTypes.oneOf(Object.keys(directions)),
    wrap: PropTypes.oneOf(Object.keys(wraps)),
    flex: PropTypes.string,
    justify: PropTypes.oneOf(Object.keys(justifies)),
    align: PropTypes.oneOf(Object.keys(aligns)),
    full: PropTypes.bool,
};
