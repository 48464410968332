//-------------------------------------------------------------
// 		WORK DETAILS
//-------------------------------------------------------------

export const worksDetails = [
    // {
    //     folder: "remotetech",

    //     project: "Remote Tech",
    //     keywords: "#startup, #iot, #b2b",
    //     desc: "No desc yet.",

    //     arrUX:
    //         "Creative solutions, Prototyping, Solid user interface, Animation Designs, Development",
    //     arrUI: "Adobe XD, Adobe Illustrator, Adobe After Effects",
    //     arrFE: "React16+, Hooks, Context API, SCSS, JS, Storybook, Lottie Animations",
    //     arrPRI: false,
    // },
    {
        folder: "Sneakers",
        project: "Sneakers",
        keywords: "#ecommerce, #retail, #sport, #b2c",
        desc: "A solid UXUI Design for an e-commerce platform.",

        arrUX:
            "Benchmark analyse, Researching, Defining personas, Defining brand X factors, Analysing stats, Defining pain points, Creative solutions, Prototyping, Solid user interface",
        arrUI: "Adobe XD, Adobe Illustrator",
        arrFE: false,
        arrPRI: false,
    },

    {
        folder: "emc",
        project: "emc3.0",
        keywords: "#dataDriven, #healthCare, #pharmaceutical, #b2b, #b2c",
        desc: "No desc yet.",

        arrUX:
            "Analysing stats, Defining pain points, Creative solutions, Prototyping, Solid user interface",
        arrUI: "Adobe XD",
        arrFE: false,
        arrPRI: false,
    },
    {
        folder: "purely",
        project: "Purely Capital",
        keywords: "#dataDriven, #dashboard, #finance, #startup, #b2b",
        desc: "No desc yet.",
        arrUX: "Creative solutions, Prototyping, Solid user interface",
        arrUI: "Adobe XD",
        arrFE: "CSS, JS, React15+, Context API, Styled Components, Storybook",
        arrPRI: false,
    },
    {
        folder: "indemand",
        project: "Datapharm Indemand",
        keywords: "#dataDriven, #healthCare, #pharmaceutical, #b2b, #b2c",
        desc: "No desc yet.",
        arrUX: "Creative solutions, Prototyping, Solid user interface",
        arrUI: "Adobe XD",
        arrFE: "React 16+, SCSS, JS, Hooks, Context API",
        arrPRI: false,
    },
    {
        folder: "itc",
        project: "ITC Travel",
        keywords: "#tourism, #travel, #b2b, #b2c, #b2d",
        desc: "No desc yet.",

        arrUX: "Creative solutions, Prototyping, Solid user interface, Animation Designs",
        arrUI: "Adobe XD, Adobe Illustrator, Adobe InDesign",
        arrFE: "JS, HTML, CSS",
        arrPRI: "Branding, Graphic Design, Leaflet, Various Print Works",
    },
    {
        folder: "equal",
        project: "Equal London",
        keywords: "#law, #consultancy, #b2c",
        desc: "No desc yet.",
        arrUI: "Adobe InDesign, Adobe Illustrator, Adobe Photoshop",
        arrFE: "CSS, JS, HTML",
        arrPRI: "Branding, Graphic Design, Leaflet, Various Print Works",
    },
    {
        folder: "sanliurfa",
        project: "History of the Beginning: Coffee Table Book",
        keywords: "#tourism, #publishing",
        desc: "No desc yet.",
        arrUI: "Adobe InDesign, Adobe Illustrator, Adobe Photoshop",
        arrPRI: "Graphic Design, Layout Design, Art Direction",
    },
    {
        folder: "cukurca",
        project: "Untold Story of Cukurca: Coffee Table Book",
        keywords: "#tourism, #publishing",
        desc: "No desc yet.",
        arrUI: "Adobe InDesign, Adobe Illustrator, Adobe Photoshop",
        arrPRI: "Graphic Design, Layout Design, Art Direction",
    },
    {
        folder: "yogaloom",
        project: "Yogaloom Yoga Studio",
        keywords: "#localBusiness, #yoga",
        desc: "No desc yet.",
        arrUI: "Adobe Illustrator",
        arrPRI: "Branding, Leaflet, SM Posts, Various Print Works",
    },
    {
        folder: "alaturka",
        project: "Alaturka Restaurant",
        keywords: "#localBusiness, #restaurant",
        desc: "No desc yet.",
        arrUI: "Adobe Illustrator",
        arrPRI: "Branding",
    },
];
