import { BrowserRouter as Router } from "react-router-dom";
import { Body, CustomMouseCursor, GlobalStore, Routes, Loading } from "dir";

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const App = (props) => {
    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <Router>
            <GlobalStore>
                <Body>
                    <CustomMouseCursor />
                    <Loading />
                    {Routes}
                </Body>
            </GlobalStore>
        </Router>
    );
};
