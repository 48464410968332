import { useState, useCallback } from "react";
import useEventListener from "@use-it/event-listener";
import _ from "lodash";

export const useScrollMove = () => {
    const [coords, setCoords] = useState([0, 0]);

    const delayedQuery = useCallback(
        _.throttle(
            () => {
                let left =
                    document.documentElement.scrollLeft ||
                    document.body.scrollLeft ||
                    window.pageXOffset;
                let top =
                    document.documentElement.scrollTop ||
                    document.body.scrollTop ||
                    window.pageYOffset;
                setCoords([top, left]);
            },
            50,
            { trailing: true },
        ),
        [],
    );

    useEventListener("scroll", delayedQuery, window, { passive: true });
    return coords;
};
