import styled, { css } from "styled-components";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------
// const StyledContainerDiv = styled.div`
//     ${(props) => {
//         return css`
//             position: relative;
//             margin-left: -10px;
//         `;
//     }}
// `;
const BubbleDiv = styled.div`
    ${({ backgroundColor, color, ...props }) => {
        return css`
            position: relative;
            display: inline-block;
            background-color: ${backgroundColor ? backgroundColor : "gray"};
            color: ${color ? color : "white"};
            border-radius: 10px;
            margin-right: 5px;
            margin-top: 10px;
            padding: 5px 10px;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 11px;
        `;
    }}
`;

//-------------------------------------------------------------
// 		STYLES and PROP VALUES
//-------------------------------------------------------------

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const Bubbles = ({ children, backgroundColor, color, ...props }) => {
    const arr = children.split(", ");

    /**
     * RETURN
     */
    return (
        <>
            {arr.map((item, key) => {
                return (
                    <BubbleDiv key={key} backgroundColor={backgroundColor} color={color}>
                        {item}
                    </BubbleDiv>
                );
            })}
        </>
    );
};
