import { useState } from "react";
import useEventListener from "@use-it/event-listener";

// import { useCallback } from "react";
// import _ from "lodash";

export const useMouseMove = () => {
    const [coords, setCoords] = useState([0, 0]);

    // const delayedQuery = useCallback(
    //     _.throttle(
    //         ({ clientX, clientY }) => {
    //             setCoords([clientX, clientY]);
    //         },
    //         50,
    //         { trailing: true },
    //     ),
    //     [],
    // );

    // useEventListener("mousemove", delayedQuery, window, { passive: true });

    const withoutDelayedQuery = (e) => {
        setCoords([e.clientX, e.clientY]);
    };

    useEventListener("mousemove", (e) => withoutDelayedQuery(e), window, { passive: true });

    return coords;
};

/*

import { MouseCords } from "dir";
const [x, y] = MouseCords();
https://codesandbox.io/s/k38lyx2q9o?file=/src/Mouse.js:95-125

 */
