import { css } from "styled-components";

//-------------------------------------------------------------
// 		DEFAULT VALUES
//-------------------------------------------------------------
const defaultBreakpoints = { xsmall: 478, small: 767, medium: 991, large: 1440, xlarge: 4000 };
const defaultBpOrder = 1;
const defaultCssProp = "background-color";
const defaultCssPropUnits = "px";
const defaultValues = [];

//-------------------------------------------------------------
// 		viaFreeBp
//-------------------------------------------------------------

/*

${viaFreeBp("background-color", "", [
                { 1200: "red" },
                { 800: "blue" },
                { 600: "green" },
                { 450: "yellow" },
            ])};

*/

export const viaFreeBp = (
    cssProp = defaultCssProp,
    cssPropUnits = defaultCssProp,
    values = defaultValues,
) => {
    const breakpointProps = values.reduce((mediaQueries, value) => {
        const [screenBreakpoint, cssPropBreakpoint] = [
            Object.keys(value)[0],
            Object.values(value)[0],
        ];
        return (mediaQueries += `
    @media screen and (max-width: ${screenBreakpoint}px) {
      ${cssProp}: ${cssPropBreakpoint}${cssPropUnits};
    }
    `);
    }, "");
    return css([breakpointProps]);
};

//-------------------------------------------------------------
// 		viaBpOrder
//-------------------------------------------------------------

/*

font-size: ${viaBpOrder(props, 3, 10, 50, "px")};

*/

export const viaBpOrder = (
    props,
    bpOrderBreak = 1,
    min = 1,
    max = 2,
    cssPropUnits = defaultCssPropUnits,
) => {
    if (props?.theme?.bpOrder || defaultBpOrder < bpOrderBreak) {
        return min + "" + cssPropUnits;
    } else {
        return max + "" + cssPropUnits;
    }
};

//-------------------------------------------------------------
// 		viaTheme
//-------------------------------------------------------------

/*

${viaTheme(props, "font-size", [10, 20, 30, 40, 50], "px")};

*/

export const viaTheme = (
    props,
    cssProp = defaultCssProp,
    val = defaultValues,
    cssPropUnits = defaultCssPropUnits,
) => {
    let returnMedia = "";
    const bps = Object.values(props?.theme?.breakpoints || defaultBreakpoints).sort(
        (a, b) => a - b,
    );
    for (let i in bps) {
        returnMedia += `
    @media screen and (min-width:${i === "0" ? 0 : bps[i - 1]}px) and (max-width:${bps[i]}px){
        ${cssProp}: ${val[i]}${cssPropUnits};
    }
        `;
    }
    return css([returnMedia]);
};

//-------------------------------------------------------------
// 		Other styled-components codes
//-------------------------------------------------------------
/*

background-color: ${props.theme.colors.brand};

& > div[aria-label="textArea"] {}

width: ${props => (props.width ? props.width : "100%")};

width: ${props => `calc(100vw - ${props.pureMarg} * 2)`};

const Box = styled.div`
    ${(props) => {
        return css`
            position: relative;
        `;
    }}
`;

align-content: ${props.screenSizeC < 3 ? "center" : null};


*/
