import { useRef, useContext } from "react";
import useEventListener from "@use-it/event-listener";
import { useScrollMove, GlobalContext } from "dir";

export const UseDragToScroll = () => {
    const [, scrollLeft] = useScrollMove();
    const { styledCursor, isSafeSize } = useContext(GlobalContext);
    const pos = useRef();

    const mouseDownHandler = (e) => {
        if (!isSafeSize) return;
        styledCursor.current = "dragToScroll";
        pos.current = {
            left: scrollLeft,
            x: e.clientX,
        };

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
    };

    useEventListener("mousedown", mouseDownHandler, window, { passive: true });

    const mouseMoveHandler = function (e) {
        const dx = e.clientX - pos.current.x;
        window.scrollTo({ top: 0, left: pos.current.left - dx });
    };

    const mouseUpHandler = function () {
        styledCursor.current = false;
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
    };

    return null;
};
