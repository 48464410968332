import { useContext, useEffect } from "react";
import { MainLayout, GlobalContext, Welcome, SkillSet, Works, Contact } from "dir";
import OnImagesLoaded from "react-on-images-loaded";
import spaceBackground from "images/space_back.jpg";
import styled, { css } from "styled-components";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------
const HiddenImage = styled.img`
    ${(props) => {
        return css`
            width: 1px;
            height: 1px;
            opacity: 0;
            display: none;
            position: absolute;
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const Home = (props) => {
    const { newPage, disableIsLoadingWithDelay } = useContext(GlobalContext);

    /**
     * Change pageName and title onLoad
     */
    useEffect(() => {
        window.scrollTo(0, 0);
        newPage({
            pageName: "home",
            title: "pxa creative",
        });
    }, []);

    /**
     * When Dom Content Loaded
     * this works with:
     * import OnImagesLoaded from "react-on-images-loaded";
     */
    const onDomContentLoadedF = () => {
        disableIsLoadingWithDelay();
    };

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <OnImagesLoaded onLoaded={onDomContentLoadedF} timeout={100000}>
            <HiddenImage src={spaceBackground} alt="" />
            <MainLayout>
                <Welcome />
                <SkillSet />
                <Works />
                <Contact />
            </MainLayout>
        </OnImagesLoaded>
    );
};
