import { useContext } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "dir";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const StyledDiv = styled.div`
    ${({ width, backImage, isSafeSize, ...props }) => {
        return css`
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            min-height: 100%;
            background: #ffffff url(${backImage}) no-repeat left center;
            background-size: cover;
            box-sizing: border-box;

            width: ${width && isSafeSize ? width : isSafeSize ? "max-content" : "100vw"};
            height: ${isSafeSize ? "100vh" : width};
            flex-direction: ${isSafeSize ? "row" : "column"};
            flex-wrap: ${isSafeSize ? "no-wrap" : "wrap"};
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const ContentDiv = ({ width, children, label, backImage, ...props }) => {
    const { isSafeSize } = useContext(GlobalContext);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <StyledDiv aria-label={label} isSafeSize={isSafeSize} width={width} backImage={backImage}>
            {children}
        </StyledDiv>
    );
};
