import { useState, useRef } from "react";
import styled, { css } from "styled-components";
import { MyLottiePlayer, viaTheme } from "dir";
import hotAirBalloonCenter from "images/hotAirBalloonCenter.json";
import hotAirBalloonToRight from "images/hotAirBalloonRight.json";
import hotAirBalloonToLeft from "images/hotAirBalloonLeft.json";
import useEventListener from "@use-it/event-listener";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const HotAirBalloonDiv = styled.div`
    ${(props) => {
        return css`
            position: sticky;
            z-index: 3;
            ${viaTheme(props, "left", [10, 20, 30, 100, 200])};
            margin-top: 150px;
            width: 190px;
            height: 450px;
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const HotAirBalloon = (props) => {
    /**
     * Define Scroll Direction
     */
    const [scrollDirection, setScrollDirection] = useState("center");
    const oldScrollValue = useRef();
    const timeOut = useRef();
    const onScroll = () => {
        defineScrollDirection();
    };
    const defineScrollDirection = () => {
        clearTimeout(timeOut.current);
        let newDirection;
        let currentScrollValue =
            document.documentElement.scrollLeft || document.body.scrollLeft || window.pageXOffset;
        // scrollTop.current =
        //     document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;

        if (currentScrollValue === oldScrollValue.current || 0) {
            newDirection = "center";
            oldScrollValue.current = currentScrollValue;
        } else if (currentScrollValue < oldScrollValue.current || 0) {
            newDirection = "left";
            oldScrollValue.current = currentScrollValue;
            timeOut.current = setTimeout(defineScrollDirection, 500);
        } else {
            newDirection = "right";
            oldScrollValue.current = currentScrollValue;
            timeOut.current = setTimeout(defineScrollDirection, 500);
        }

        if (newDirection !== scrollDirection) setScrollDirection(newDirection);
    };
    useEventListener("scroll", onScroll);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <HotAirBalloonDiv>
            <MyLottiePlayer
                name="hotAirBalloon"
                width="190"
                src={
                    scrollDirection === "center"
                        ? hotAirBalloonCenter
                        : scrollDirection === "right"
                        ? hotAirBalloonToRight
                        : hotAirBalloonToLeft
                }
                autoplay
                loop
            />
        </HotAirBalloonDiv>
    );
};
