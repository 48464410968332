import { useContext } from "react";
import styled, { css } from "styled-components";
import { viaTheme, findInSource } from "dir";
import PropTypes from "prop-types";
import { GlobalContext } from "dir";

//-------------------------------------------------------------
// 		STYLES and PROP VALUES
//-------------------------------------------------------------

const patterns = {
    default: styled.button`
        ${(props) => {
            return css`
                position: relative;
                /* background-color: #ffffff; */
                color: ${props.theme.colors.default};
                cursor: none;
                font-weight: 700;
                ${viaTheme(props, "font-size", [13, 13, 13, 14, 14])};
                border: none;
                padding: 0px 0px 5px 0px;
                border-radius: 0px;
                margin: 20px 0px;

                &:after {
                    content: "";
                    margin-top: 4px;
                    position: relative;
                    display: block;
                    width: 100%;
                    transition: all 250ms ease-in;
                    border-bottom: 3px solid ${props.theme.colors.brand};
                }

                &:hover {
                    &:after {
                        width: 0px;
                    }
                }

                @media screen and (max-width: 991px), (max-height: 500px) {
                    cursor: default;
                }

                @media screen and (min-width: 991px) and (min-height: 500px) {
                    cursor: none;
                }
            `;
        }}
    `,
};

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const Button = ({ pattern, display, onClick, ...props }) => {
    const Comp = findInSource(patterns, pattern);
    const { styledCursor } = useContext(GlobalContext);

    return (
        <Comp
            display={props.display}
            onMouseOver={() => (styledCursor.current = "circle")}
            onMouseOut={() => (styledCursor.current = false)}
            onClick={() => onClick()}
            {...props}
        >
            {props.children}
        </Comp>
    );
};

//-------------------------------------------------------------
// 		PROP TYPES
//-------------------------------------------------------------

Button.propTypes = {
    pattern: PropTypes.oneOf(Object.keys(patterns)),
    display: PropTypes.string,
};
