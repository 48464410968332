import { useRef, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "dir";
import PropTypes from "prop-types";
import { Player } from "@lottiefiles/react-lottie-player";
import useEventListener from "@use-it/event-listener";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const StyledContainer = styled.div`
    ${(props) => {
        return css`
            width: ${props.width || "max-content"};
            max-width: max-content;
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const MyLottiePlayer = ({
    name = "myLottiePlayer",
    width = "300",
    src = "https://assets3.lottiefiles.com/packages/lf20_XZ3pkn.json",
    autoplay = false,
    hover = false,
    loop = false,
    speed = "1",
    direction = "1",
    scroll = false,
    scrollDirection = "top", // left
    scrollEnterExit = [25, 75],
    customLastFrame,
    stopBeforeLastFrame = 0,
    ...props
}) => {
    const { WINW, WINH, bpOrder } = useContext(GlobalContext);
    const lottieContainer = useRef();
    const myLottie = useRef();
    const myLottieData = useRef();
    const lastFrame = useRef();
    const lastFrameFreezerMarg =
        customLastFrame ||
        (() => {
            let speedNumber = Number(speed) || 1;
            return speedNumber < 2 ? 1 : speedNumber < 4 ? 2 : speedNumber < 6 ? 3 : 4;
        })();
    const isMouseOver = useRef(false);
    const widthFinder = (width, bpOrder) => {
        const typeOf = typeof width;
        if (typeOf === "string" || typeOf === "number") return width || "100%";
        else return width[bpOrder - 1] || "100%";
    };

    /**
     * onEvent Handler
     */
    const onEvent = (e) => {
        if (!myLottieData || !myLottieData.current) return;

        switch (e) {
            case "load":
                lastFrame.current = myLottieData.current.animationData.op - 1;
                if (hover || scroll) myLottie.current.setSeeker(0.0000000001);
                myLottie.current.setPlayerSpeed(speed);
                onScrollCalculateNewCurrentFrame(lottieContainer);
                break;
            case "frame":
                if (loop) break;
                if (
                    myLottieData.current.currentFrame >=
                    lastFrame.current - lastFrameFreezerMarg - stopBeforeLastFrame
                ) {
                    myLottie.current.pause();
                    myLottie.current.setSeeker(
                        lastFrame.current - lastFrameFreezerMarg - stopBeforeLastFrame - 0.1,
                    );
                }
                break;
            default:
                break;
        }
    };
    /**
     * onMouseOver Handler
     */
    const onMouseOver = () => {
        isMouseOver.current = true;
        if (!hover || scroll) return;
        myLottie.current.setPlayerSpeed(speed);
        myLottie.current.setPlayerDirection(1);
        myLottie.current.play();
    };

    /**
     * onMouseOut Handler
     */
    const onMouseOut = () => {
        isMouseOver.current = false;
        if (!hover || scroll) return;

        if (loop) {
            myLottie.current.pause();
            return;
        }

        myLottie.current.setPlayerSpeed(Number(speed) * 2);
        myLottie.current.setPlayerDirection(-1);
        myLottie.current.play();
    };

    /**
     * onScroll Handler
     */
    const startPercentage = !isNaN(scrollEnterExit[0]) ? scrollEnterExit[0] : 25;
    const endPercentage = !isNaN(scrollEnterExit[1]) ? scrollEnterExit[1] : 75;
    const onScroll = () => onScrollCalculateNewCurrentFrame(lottieContainer);
    useEventListener("scroll", onScroll);
    useEffect(() => onScrollCalculateNewCurrentFrame(lottieContainer), [WINW, WINH]);
    const onScrollCalculateNewCurrentFrame = (lottieContainer) => {
        if (!lottieContainer.current || !scroll) return;
        const rect = lottieContainer.current.getBoundingClientRect();
        let dimension, distance, screenSize;

        if (scrollDirection === "left") {
            dimension = rect.width;
            distance = rect.left;
            screenSize = WINW;
        } else {
            dimension = rect.height;
            distance = rect.top;
            screenSize = WINH;
        }

        const zeroPercentPoint = (dimension / 100) * startPercentage;
        const hundredPercentPoint = (dimension / 100) * endPercentage;

        const startPoint = distance - screenSize + zeroPercentPoint;
        const endPoint = distance + hundredPercentPoint;

        if (startPoint >= 0) {
            myLottie.current.setSeeker(0);
        } else if (endPoint <= 0) {
            myLottie.current.setSeeker(lastFrame.current);
        } else {
            const artificialWidth = hundredPercentPoint - zeroPercentPoint + screenSize;
            const unit = lastFrame.current / artificialWidth;
            myLottie.current.setSeeker(startPoint * -1 * unit);
        }
    };

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <StyledContainer
            aria-label={name}
            width={widthFinder(width, bpOrder)}
            ref={lottieContainer}
            onMouseEnter={onMouseOver}
            onMouseLeave={onMouseOut}
        >
            <Player
                lottieRef={(instance) => (myLottieData.current = instance)}
                onEvent={(e) => onEvent(e)}
                ref={myLottie}
                autoplay={hover || scroll ? false : autoplay}
                loop={scroll ? false : loop}
                // speed={speed} // bu prop çalışMIYOR. Açma. Do not open this prop. Bunun yerine "load"da speedi setSpeed ile değiştiriyoruz.
                src={src}
                direction={direction}
                style={{ width: widthFinder(width, bpOrder) }}
            ></Player>
        </StyledContainer>
    );
};

//-------------------------------------------------------------
// 		PROP TYPES
//-------------------------------------------------------------

MyLottiePlayer.propTypes = {
    name: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    src: PropTypes.object,
    autoplay: PropTypes.bool,
    hover: PropTypes.bool,
    loop: PropTypes.bool,
    speed: PropTypes.string,
    direciton: PropTypes.string,
    scroll: PropTypes.bool,
    scrollDirection: PropTypes.oneOf(["top", "left"]),
    scrollEnterExit: PropTypes.array,
    customLastFrame: PropTypes.string,
    stopBeforeLastFrame: PropTypes.string,
};
