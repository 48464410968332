import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    html {
        height:100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;

        

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            height: 100%;
            overflow: hidden;
        }
    }



    body {
        font-family: ${({ theme }) => theme.fontFamily};
        background-color: ${({ theme }) => theme.colors.body};
        color: ${({ theme }) => theme.colors.default};
        margin: 0px;
        padding: 0px;
        height:100%;
        min-height: 100%;
        user-select: none;
        font-weight:400;


        @media screen and (max-width: 991px), (max-height: 500px) {
            cursor: default;
            overflow-y:scroll;
            overflow-x:hidden;

        }

        @media screen and (min-width: 991px) and (min-height: 500px) {
            cursor:none;
            overflow-y:hidden;
            overflow-x:auto;

            &::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        

    }

    #root {
      width:100%;
      height:100%;
    }

    a.button,
        button,
        html input[type="button"],
        input[type="reset"],
        input[type="submit"] {
                        background: none;
                        background: none;
                        background: none;
                        background: none;
    }

`;

export const theme = {
    fontFamily: '"Montserrat", "Helvetica", "Verdana", sans-serif',
    breakpoints: {
        xsmall: 478, // 0 - 478 mobile
        small: 767, // 478-767 mobile landscape
        medium: 991, // 767-991 tablet
        large: 1440, /// 991 - 1440 desktop
        xlarge: 4000, // 1440 - +++ wide screens
    },
    colors: {
        body: "white",
        default: "#454545",
        brand: "#E52027",
        brandLight: "#E84037",
        softGrey: "#909090",
        softerGrey: "#DADADA",
        white: "#ffffff",
    },
};
