import { Fragment, useContext } from "react";
import styled, { css } from "styled-components";
import {
    GlobalContext,
    viaTheme,
    ContentDiv,
    Text,
    ScrollAnimation,
    Bubbles,
    MyLottiePlayer,
    // Button,
} from "dir";
import imajUstu from "images/imajUstu.json";
import { worksDetails } from "../WorksDetails";
import seperatorText_works from "images/seperatorText_works.json";
import seperatorText_works_hor from "images/seperatorText_works_hor.json";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const StyledWelcome = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: 100%;
            padding-top: 150px;
            box-sizing: border-box;
            ${viaTheme(props, "margin-left", [20, 40, 80, 100, 200])};

            ${viaTheme(props, "width", [300, 400, 500, 500, 500], "px")};
        `;
    }}
`;
const BackNumber = styled.div`
    ${(props) => {
        return css`
            position: relative;
            color: black;
            font-size: 500px;
            opacity: 0.05;
            margin-top: ${props.isSafeSize
                ? "-" + Math.floor(Math.random() * (350 - 50 + 1) + 50) + "px"
                : "-200px"};
            text-transform: uppercase;
            font-weight: 900;
            letter-spacing: -20px;
            margin-left: ${props.isSafeSize ? "100px" : "0px"};
            height: ${props.isSafeSize ? false : "450px"};
            width: ${props.isSafeSize ? false : "100vw"};
        `;
    }}
`;
const WorkCol = styled(StyledWelcome)`
    ${(props) => {
        return css`
            width: min-content;
            padding-top: ${props.isSafeSize ? "150px" : "50px"};
            padding-bottom: ${props.isSafeSize ? "150px" : false};
            flex-direction: "column";
            margin-right: 50px;
            /* justify-content: space-between; */
        `;
    }}
`;
const InfoDiv = styled.div`
    ${(props) => {
        return css`
            ${viaTheme(props, "padding-right", [50, 75, 75, 100, 100], "px")};
            ${viaTheme(props, "min-width", [300, 400, 400, 500, 600], "px")};
        `;
    }}
`;
const LottieDiv = styled.div`
    ${(props) => {
        return css`
            position: absolute;
            left: 0px;
            top: 0px;
        `;
    }}
`;
const ImgDiv = styled.div`
    ${(props) => {
        return css`
            position: relative;
            display: flex;
            ${viaTheme(props, "width", ["90vw", "80vw", "500px", "600px", "600px"], "")};
            ${viaTheme(
                props,
                "height",
                [
                    "calc(90vw * 0.56)",
                    "calc(80vw * 0.56)",
                    "calc(500px * 0.56)",
                    "calc(600px * 0.56)",
                    "calc(600px * 0.56)",
                ],
                "",
            )};
            margin-top: 50px;

            & > img {
                transition: all 1s ease;
                margin-top: ${props.isSafeSize ? "0px" : "50px"};

                ${viaTheme(props, "width", ["90vw", "80vw", "500px", "600px", "600px"], "")};
                ${viaTheme(
                    props,
                    "height",
                    [
                        "calc(90vw * 0.56)",
                        "calc(80vw * 0.56)",
                        "calc(500px * 0.56)",
                        "calc(600px * 0.56)",
                        "calc(600px * 0.56)",
                    ],
                    "",
                )};
                height: auto;
            }
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const Works = (props) => {
    const { WINH, isSafeSize, styledCursor, goTo } = useContext(GlobalContext);
    const clickableThumbs = false;

    /**
     * Return
     */
    return (
        <ContentDiv label="works">
            <MyLottiePlayer
                name="seperatorText_works"
                width="500px"
                src={isSafeSize ? seperatorText_works : seperatorText_works_hor}
                scroll={true}
                scrollDirection={isSafeSize ? "left" : "top"}
                autoplay={false}
                loop={false}
                speed="1"
                scrollEnterExit={[0, 50]}
            />
            <StyledWelcome>
                <Text pattern="seperator">
                    Every project has its own needs.
                    {/*Countless projects I accomplished in 20 years
                    gave me the knowledge of fulfil these needs.*/}
                </Text>
                <Text>
                    B2B, B2C or B2D... From startups to global brands... From e-commerce journeys to
                    data-handling/verifying/presenting processes till colourful, animated landing
                    pages... As a creative designer/developer I can look upon from the point which
                    the client needs.
                </Text>
            </StyledWelcome>
            {worksDetails.map((item, key) => {
                return (
                    <Fragment key={key}>
                        <BackNumber isSafeSize={isSafeSize}>{key + 1}</BackNumber>
                        <WorkCol isSafeSize={isSafeSize}>
                            <InfoDiv>
                                <ScrollAnimation
                                    scrollFromX={isSafeSize ? true : false}
                                    cssClassName="fadeIn"
                                >
                                    <b>Project: {item.project}</b>
                                    <br />
                                    <b>Keys: </b> {item.keywords}
                                    <br />
                                    {!item.arrUX ? null : (
                                        <Bubbles backgroundColor="#E8E2D5" color="#454545">
                                            {item.arrUX}
                                        </Bubbles>
                                    )}
                                    {!item.arrUI ? null : (
                                        <Bubbles backgroundColor="#E6D6D9" color="#454545">
                                            {item.arrUI}
                                        </Bubbles>
                                    )}
                                    {!item.arrFE ? null : (
                                        <Bubbles backgroundColor="#D6DDDF" color="#454545">
                                            {item.arrFE}
                                        </Bubbles>
                                    )}
                                    {!item.arrPRI ? null : (
                                        <Bubbles backgroundColor="#cec7de" color="#454545">
                                            {item.arrPRI}
                                        </Bubbles>
                                    )}
                                </ScrollAnimation>
                            </InfoDiv>
                            <ImgDiv
                                WINH={WINH}
                                isSafeSize={isSafeSize}
                                onMouseOver={() =>
                                    clickableThumbs ? (styledCursor.current = "circle") : false
                                }
                                onMouseOut={() =>
                                    clickableThumbs ? (styledCursor.current = false) : false
                                }
                                onClick={() => (clickableThumbs ? goTo(item.folder) : false)}
                            >
                                {!isSafeSize ? null : (
                                    <LottieDiv>
                                        <MyLottiePlayer
                                            name="imajUstu"
                                            width="100%"
                                            src={imajUstu}
                                            speed="3"
                                            autoplay={false}
                                            hover={true}
                                            loop={false}
                                        />
                                    </LottieDiv>
                                )}
                                <img
                                    src={
                                        require("pages/Works/" + item.folder + "/01_homeThumb.jpg")
                                            .default
                                    }
                                    alt={item.desc}
                                />
                            </ImgDiv>
                        </WorkCol>
                    </Fragment>
                );
            })}
        </ContentDiv>
    );
};
