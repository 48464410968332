import { useContext, useEffect } from "react";
import {
    GlobalContext,
    MainLayout,
    WorksCover,
    OnlyTitle,
    OnlyText,
    TextAndTitle,
    MultiImage,
    SingleLongImage,
    Contact,
    MyLottiePlayer,
} from "dir";
import OnImagesLoaded from "react-on-images-loaded";
import { worksDetails } from "../../WorksDetails";
import styled, { css } from "styled-components";
import seperatorText_whatIvedone from "images/seperatorText_whatIvedone.json";
import seperatorText_delivery from "images/seperatorText_delivery.json";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const Sections = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            position: relative;
            display: flex;
            flex-direction: ${props.isSafeSize ? "row" : "column"};
            flex-wrap: nowrap;
            max-height: ${props.isSafeSize ? "100vh" : false};
            max-width: ${props.isSafeSize ? false : "100vw"};
            margin-top: ${props.isSafeSize ? false : "-150px"};
            background-color: white;
        `;
    }}
`;
const VerticalLine = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            width: 1px;
            height: 300px;
            background-color: lightgray;
            position: relative;
            margin-right: 150px;
            margin-top: 230px;
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const Sneakers = (props) => {
    const { newPage, disableIsLoadingWithDelay, history, isSafeSize } = useContext(GlobalContext);
    const projectName = history.location.pathname.split("/").join("");
    const projectDetails = worksDetails.filter((item) => item.project === projectName)[0];
    // console.log(projectDetails);

    /**
     * Change pageName and title onLoad
     */
    useEffect(() => {
        window.scrollTo(0, 0);
        newPage({
            pageName: projectDetails.project,
            title: "pxa creative | " + projectDetails.project,
        });
    }, []);

    /**
     * When Dom Content Loaded
     * this works with:
     * import OnImagesLoaded from "react-on-images-loaded";
     */
    const onDomContentLoadedF = () => {
        disableIsLoadingWithDelay();
    };

    /**
     * Return
     */
    return (
        <OnImagesLoaded onLoaded={onDomContentLoadedF} timeout={100000}>
            <MainLayout>
                <Sections aria-label="Summary" isSafeSize={isSafeSize} style={{ paddingLeft: 0 }}>
                    <WorksCover projectDetails={projectDetails} />
                    <TextAndTitle title="About Project">
                        The brand sales popular brands’ sport-shoes, clothing and relevant
                        equipment. Due to its strategy, they concentrate on basketball and sneakers
                        culture. In the city’s 15 popular location, they have branches and sale
                        point. The products they sell are luxury and expensive therefore the target
                        audience is men-women who mostly white-collar, high-income, 25-35 aged,
                        loves USA culture and brands. Also, they have an online e-commerce platform
                        that they want to improve. By this way, they want to widen the area they can
                        reach.
                    </TextAndTitle>
                    <SingleLongImage
                        folder={projectDetails.folder}
                        arr={{ path: "im18.jpg", width: 800 }}
                    />
                </Sections>

                {/* ---------------------------------------
                    WHAT I HAVE DONE
                --------------------------------------- */}

                <Sections
                    aria-label="What I've Done"
                    isSafeSize={isSafeSize}
                    style={{ paddingLeft: 0 }}
                >
                    <MyLottiePlayer
                        name="seperatorText_whatIvedone"
                        width="500px"
                        src={seperatorText_whatIvedone}
                        scroll={true}
                        scrollDirection={isSafeSize ? "left" : "top"}
                        autoplay={false}
                        loop={false}
                        speed="1"
                        scrollEnterExit={[0, 50]}
                    />
                    <TextAndTitle title="What I've Done" aria-label="What I've Done">
                        After several department meetup and user & survey analyse, I've determined
                        several issues, interface improvement and client' expectations.
                    </TextAndTitle>

                    <OnlyText>
                        <b>Issue:</b> The existed site was supporting only mobile devices and 1024
                        px wide desktop devices. On wider screens, a large white space was occuring
                        and the screen couldn’t be used correctly. It was looking old-technolohy.
                        Also, the brand had demanded to use Bootstrap4.
                    </OnlyText>
                    <MultiImage
                        folder={projectDetails.folder}
                        arr={[
                            { path: "im01.png", width: 300 },
                            { path: "im02.png", width: 300 },
                        ]}
                    />

                    <OnlyText>
                        <b>Solution:</b> According to page stats, mobile visitors are more than
                        desktop users. But, the visitors who complete the purchase are using
                        commonly desktop devices. These are common stats same with global usage.
                        Users prefer to discover on mobile and then prefer to decide and buy on
                        wider screens. According to present global stats and predictions, 1920 and +
                        resulations usage are increasing, will increase more. But, BS4 is a
                        mobile-only framework and doesn’t support wide screens.
                        <br /> As a solution, I’ve decided to customise BS4. The new-edited BS4 will
                        support 7 BP and screens over 1900px.
                    </OnlyText>
                    <MultiImage
                        folder={projectDetails.folder}
                        arr={[{ path: "im03.png", width: 300 }]}
                    />

                    <VerticalLine />

                    <OnlyText>
                        <b>Issue:</b> The brand’s marketing and the sales team were using the same
                        pre-designed-page layout all the time. For adding new contents to the pages
                        they needed designers, developers and it takes time.
                        <br />
                        <b>Solution:</b> In an on-going life, a platform always needs new landing
                        pages, new banner areas, new campaigns, new slider areas etc. As a solution,
                        I’ve decided to design widget-boxes instead of design pages. All present and
                        future possibilities calculated and I’ve designed over 450 boxes that
                        compatible with the grid system. This solution is very similar to React’s
                        component-class mentality.
                    </OnlyText>
                    <MultiImage
                        folder={projectDetails.folder}
                        arr={[
                            { path: "im04.jpg", width: 400 },
                            { path: "im05.jpg", width: 400 },
                        ]}
                    />

                    <VerticalLine />

                    <OnlyText>
                        <b>Issue:</b> The visitors didn’t know the brand has many luxury branches in
                        the city. So, they thought the website is an ordinary, unknown (untrustable)
                        trade platform. As a common problem in this market for customers, finding
                        support or help after purchase is difficult. And also the products are quite
                        expensive so visitors don’t want to risk to buy.
                        <br />
                        <b>Solution:</b> Using the “Stores” and “Support” links at the strategic
                        points of the header. By this way, visitors will see trust-saver links and
                        products together and they will feel the brand is reliable. Creating a new
                        selectable service on the product detail page as “Buy Online & Receive From
                        Store”. Also mention this service “FAQ” or “Choose Us” info-pages.
                    </OnlyText>
                    <MultiImage
                        horizontal
                        folder={projectDetails.folder}
                        arr={[
                            { path: "im06.jpg", width: 400 },
                            { path: "im07.jpg", width: 400 },
                        ]}
                    />

                    <VerticalLine />

                    <OnlyText>
                        <b>Issue:</b> The platform had a full-screen slider area. For some campaign
                        or news, they were producing their own images and contexts. But in some
                        conditions, they were having to use products’/campaigns’ original images
                        which were not suitable for the slider area. This situation was causing an
                        improper intro view.
                        <br />
                        <b>Solution:</b> As a solution, I’ve planned two different shapes of slider
                        area. By this way, the content manager can choose a proper shape.
                    </OnlyText>
                    <MultiImage
                        folder={projectDetails.folder}
                        arr={[{ path: "im08.jpg", width: 400 }]}
                    />

                    <VerticalLine />

                    <OnlyText>
                        <b>Issue:</b> Shoe images were horizontal but textiles’ were vertical.
                        Standart thumb system was causing visual derangement and unnecessary gaps.
                        <br />
                        <b>Solution:</b> As a solution, I’ve planned two different shapes for
                        product boxes. The form will auto-change according to the type of product’s
                        image.
                    </OnlyText>
                    <MultiImage
                        horizontal
                        folder={projectDetails.folder}
                        arr={[
                            { path: "im09.jpg", width: 400 },
                            { path: "im10.jpg", width: 400 },
                        ]}
                    />

                    <VerticalLine />

                    <OnlyText>
                        <b>Issue:</b> The website had 2 diverse zones for products and blog content.
                        Visitors weren’t switching between 2 zones. Because of that, the website
                        wasn’t using the power of its content.
                        <br />
                        <b>Solution:</b> According to the stats, content marketing costs 62% less
                        than other ads. Also, 95% of customers consider content as trustworthy when
                        evaluating a company and its offerings. Telling apart contents and products
                        each other is a big UX fault. On the contrary, we have to connect them
                        together than ever before. As a solution, I’ve placed a content connection
                        through all the product detail and listing pages and I’ve reminded the
                        customer correct information and collections. This solution’s helped
                        customer to decide and to buy a product.
                    </OnlyText>
                    <MultiImage
                        horizontal
                        folder={projectDetails.folder}
                        arr={[{ path: "im11.jpg", width: 800 }]}
                    />

                    <VerticalLine />

                    <OnlyText>Also, I've created a design system for the client.</OnlyText>
                    <MultiImage
                        horizontal
                        folder={projectDetails.folder}
                        arr={[
                            { path: "im12.jpg", width: 300 },
                            { path: "im13.jpg", width: 300 },
                        ]}
                    />
                </Sections>

                {/* ---------------------------------------
                    DELIVERY   
                --------------------------------------- */}

                <Sections aria-label="Delivery" isSafeSize={isSafeSize} style={{ paddingLeft: 0 }}>
                    <MyLottiePlayer
                        name="seperatorText_delivery"
                        width="500px"
                        src={seperatorText_delivery}
                        scroll={true}
                        scrollDirection={isSafeSize ? "left" : "top"}
                        autoplay={false}
                        loop={false}
                        speed="1"
                        scrollEnterExit={[0, 50]}
                    />
                    <OnlyTitle>Delivery</OnlyTitle>

                    <MultiImage
                        horizontal
                        folder={projectDetails.folder}
                        arr={[
                            { path: "im14.gif", width: 800 },
                            { path: "im15.gif", width: 800 },
                            { path: "im16.gif", width: 800 },
                            { path: "im17.gif", width: 400 },
                        ]}
                    />
                    <SingleLongImage
                        folder={projectDetails.folder}
                        arr={{ path: "im19.jpg", width: 400 }}
                    />
                </Sections>

                {/* ---------------------------------------
                    CONTACT   
                --------------------------------------- */}

                <Sections
                    aria-label="Contact"
                    isSafeSize={isSafeSize}
                    style={{ paddingLeft: 0, zIndex: 3 }}
                >
                    <Contact />
                </Sections>
            </MainLayout>
        </OnImagesLoaded>
    );
};
