import { useContext } from "react";
import styled, { css } from "styled-components";
import {
    viaTheme,
    ContentDiv,
    Text,
    ScrollAnimation,
    Bubbles,
    GlobalContext,
    MyLottiePlayer,
} from "dir";
import seperatorText_skillset from "images/seperatorText_skillset.json";
import seperatorText_skillset_hor from "images/seperatorText_skillset_hor.json";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const StyledWelcome = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: 100%;
            padding-top: 150px;
            box-sizing: border-box;
            ${viaTheme(props, "margin-left", [20, 40, 80, 100, 200])};

            ${viaTheme(props, "width", [300, 400, 500, 500, 500], "px")};
        `;
    }}
`;
const EntireSkillCols = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            position: relative;
            display: flex;
            ${viaTheme(props, "margin-left", [20, 40, 80, 0, 0])};
            flex-direction: ${props.isSafeSize ? "row" : "column"};
        `;
    }}
`;
const SkillSetCols = styled.div`
    ${(props) => {
        return css`
            z-index: 3;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: 100%;
            padding-top: ${props.isSafeSize ? "200px" : "20px"};
            box-sizing: border-box;
            width: ${props.isSafeSize ? "300px" : "90%"};
            margin-right: ${props.isSafeSize ? "100px" : "50px"};
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const SkillSet = (props) => {
    const { isSafeSize } = useContext(GlobalContext);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <ContentDiv label="skillset">
            <MyLottiePlayer
                name="seperatorText_skillset"
                width="500px"
                src={isSafeSize ? seperatorText_skillset : seperatorText_skillset_hor}
                scroll={true}
                scrollDirection={isSafeSize ? "left" : "top"}
                autoplay={false}
                loop={false}
                speed="1"
                scrollEnterExit={[0, 50]}
            />

            <StyledWelcome isSafeSize={isSafeSize}>
                <Text pattern="seperator">I know...</Text>
                <Text>
                    These days, while there are lots of tools, applications, packages; the key skill
                    is researching and speed of learning. And yes, this is what I have. Also, here
                    are the major tools, skills I can use:
                </Text>
            </StyledWelcome>
            <ScrollAnimation scrollFromX={isSafeSize ? true : false} cssClassName="fadeIn">
                <EntireSkillCols isSafeSize={isSafeSize}>
                    <SkillSetCols isSafeSize={isSafeSize}>
                        <Text>
                            <b>UX Design</b>
                            <br />
                            <Bubbles backgroundColor="#E8E2D5" color="#454545">
                                Benchmark analyse, Researching, Defining personas, Defining brand X
                                factors, Analysing surveys, Analysing AB tests, Analysing heatmaps,
                                Analysing stats, Defining pain points, Finding creative solutions,
                                User journey map, Wireframing, Prototyping, Solid user interface
                            </Bubbles>
                        </Text>
                    </SkillSetCols>
                    <SkillSetCols isSafeSize={isSafeSize}>
                        <Text>
                            <b>Design</b>
                            <br />
                            <Bubbles backgroundColor="#E6D6D9" color="#454545">
                                Adobe XD, Figma, Adobe Photoshop, Adobe Illustrator, Adobe InDesign,
                                Adobe After Effects
                            </Bubbles>
                        </Text>
                    </SkillSetCols>
                    <SkillSetCols isSafeSize={isSafeSize}>
                        <Text>
                            <b>FE Development</b>
                            <br />
                            <Bubbles backgroundColor="#D6DDDF" color="#454545">
                                React 15+, HTML, CSS, SCSS, JS
                            </Bubbles>
                            <br />
                            <br />
                            <Bubbles backgroundColor="#D6DDDF" color="#454545">
                                Hooks, Context API, Reducer, Styled Components, Storybook, React
                                Testing Lib, Jest, Lottie Animations, more...
                            </Bubbles>
                        </Text>
                    </SkillSetCols>
                    <SkillSetCols isSafeSize={isSafeSize}>
                        <Text>
                            <b>Other</b>
                            <br />
                            <Bubbles backgroundColor="#cec7de" color="#454545">
                                Branding, Graphic Design, Layout Design, Art Direction, Coffee Table
                                Book, Leaflet, SM Posts, Rollout, Various Print Works
                            </Bubbles>
                        </Text>
                    </SkillSetCols>
                </EntireSkillCols>
            </ScrollAnimation>
        </ContentDiv>
    );
};
