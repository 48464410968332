import { useState, useRef, useContext } from "react";
import styled, { css } from "styled-components";
import { viaTheme, GlobalContext } from "dir";
import useEventListener from "@use-it/event-listener";
import balon from "images/hotairBalloon.svg";
import golge from "images/hotairBalloonGolge.svg";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const HotAirBalloonDiv = styled.div`
    ${(props) => {
        return css`
            display: flex;
            flex-direction: column;
            position: sticky;
            z-index: 3;
            ${viaTheme(props, props.isSafeSize ? "left" : "top", [20, 20, 30, 100, 200])};
            margin-top: ${props.isSafeSize ? "150px" : false};
            ${viaTheme(props, "width", [95, 95, 190, 190, 190])};
            ${viaTheme(props, "height", [225, 225, 450, 450, 450])};
            /* width: ${props.isSafeSize ? "190px" : "95px"};
            height: ${props.isSafeSize ? "450px" : "225px"}; */
            align-self: ${props.isSafeSize ? false : "flex-end"};
            margin-right: ${props.isSafeSize ? false : "30px"};
        `;
    }}
`;
const BalonDiv = styled.div`
    ${(props) => {
        return css`
            height: 420px;
            box-sizing: border-box;
        `;
    }}
`;
const GolgeDiv = styled.div`
    ${(props) => {
        return css`
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 15px;
        `;
    }}
`;
const Balon = styled.img`
    ${({ scrollDirection, ...props }) => {
        return css`
            /* animation: upDown 3s ease-in-out infinite; */
            animation: ${props.isSafeSize
                ? "upDown 3s ease-in-out infinite"
                : "upDownMobile 3s ease-in-out infinite"};
            transition: ${scrollDirection === "center"
                ? "transform 500ms ease-in-out"
                : "transform 250ms ease-in-out"};
            /* transition: transform 1000ms ease-in-out; */
            transform: ${scrollDirection === "right"
                ? "rotate(15deg)"
                : scrollDirection === "left"
                ? "rotate(-15deg)"
                : "rotate(0deg)"};

            @keyframes upDown {
                0% {
                    padding-top: 0px;
                }
                50% {
                    padding-top: 50px;
                }
                100% {
                    padding-top: 0px;
                }
            }
            @keyframes upDownMobile {
                0% {
                    padding-top: 0px;
                }
                50% {
                    padding-top: 30px;
                }
                100% {
                    padding-top: 0px;
                }
            }
        `;
    }}
`;
const Golge = styled.img`
    ${(props) => {
        return css`
            animation: upDown2 3s ease-in-out infinite;
            opacity: 0.2;

            @keyframes upDown2 {
                0% {
                    width: 100px;
                }
                50% {
                    width: 170px;
                    opacity: 1;
                }
                100% {
                    width: 100px;
                }
            }
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const HotAirBalloon2 = (props) => {
    const { isSafeSize } = useContext(GlobalContext);
    /**
     * Define Scroll Direction
     */
    const [scrollDirection, setScrollDirection] = useState("center");
    const oldScrollValue = useRef();
    const timeOut = useRef();
    const onScroll = () => {
        defineScrollDirection();
    };
    const defineScrollDirection = () => {
        clearTimeout(timeOut.current);
        let newDirection;
        let currentScrollValue =
            document.documentElement.scrollLeft || document.body.scrollLeft || window.pageXOffset;
        // scrollTop.current =
        //     document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;

        if (currentScrollValue === oldScrollValue.current || 0) {
            newDirection = "center";
            oldScrollValue.current = currentScrollValue;
        } else if (currentScrollValue < oldScrollValue.current || 0) {
            newDirection = "left";
            oldScrollValue.current = currentScrollValue;
            timeOut.current = setTimeout(defineScrollDirection, 250);
        } else {
            newDirection = "right";
            oldScrollValue.current = currentScrollValue;
            timeOut.current = setTimeout(defineScrollDirection, 250);
        }

        if (newDirection !== scrollDirection) setScrollDirection(newDirection);
    };
    useEventListener("scroll", onScroll);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <HotAirBalloonDiv isSafeSize={isSafeSize}>
            <BalonDiv isSafeSize={isSafeSize}>
                <Balon src={balon} scrollDirection={scrollDirection} alt="" />
            </BalonDiv>
            <GolgeDiv isSafeSize={isSafeSize}>
                <Golge src={golge} alt="" />
            </GolgeDiv>
        </HotAirBalloonDiv>
    );
};
