import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { MyLottiePlayer, GlobalContext } from "dir";
import logoAnimation from "images/logoLottie.json";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const StyledContainer = styled(({ tag = "div", children, ...props }) =>
    React.createElement(tag, props, children),
)`
    ${(props) => {
        return css`
            display: ${props.display || "flex"};
            position: ${props.position || "relative"};
            width: max-content;
            box-sizing: border-box;
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const HeaderLogo = (props) => {
    const { styledCursor, goTo, isSafeSize } = useContext(GlobalContext);

    return (
        <StyledContainer
            onMouseOver={() => (styledCursor.current = "circle")}
            onMouseOut={() => (styledCursor.current = false)}
            onClick={() => goTo("")}
        >
            <MyLottiePlayer
                name="logoAnimation"
                width={isSafeSize ? "375px" : "325px"}
                src={logoAnimation}
                hover={isSafeSize ? true : false}
                speed="2"
            />
        </StyledContainer>
    );
};
