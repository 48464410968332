import styled, { css } from "styled-components";
import { useRef, useLayoutEffect, useState, useContext } from "react";
import useEventListener from "@use-it/event-listener";
import { GlobalContext } from "dir";
import "./ScrollAnimation.scss";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------
const StyledContainerDiv = styled.div`
    ${(props) => {
        return css`
            position: relative;
        `;
    }}
`;

//-------------------------------------------------------------
// 		STYLES and PROP VALUES
//-------------------------------------------------------------

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const ScrollAnimation = ({
    children,
    scrollFromX,
    cssClassName,
    inViewportFunction,
    outViewportFunction,
    ...props
}) => {
    const [whereAmI, setWhereAmI] = useState("out");
    const containerDiv = useRef();
    const { WINW, WINH, isLoading } = useContext(GlobalContext);

    /**
     * ScrollListener
     */
    const onScroll = () => {
        if (!containerDiv.current) return;
        let rect = containerDiv.current.getBoundingClientRect();
        let elementWidth = Number((scrollFromX ? rect.width : rect.height).toFixed(2));
        let distanceToBorder = Number((scrollFromX ? rect.x : rect.y).toFixed(2));
        let screenSize = scrollFromX ? WINW : WINH;

        // console.log(distanceToBorder, screenSize, distanceToBorder + screenSize + elementWidth);

        if (!isLoading && distanceToBorder < screenSize && distanceToBorder + elementWidth > 0)
            setWhereAmI("in");
        else setWhereAmI("out");
    };
    useEventListener("scroll", onScroll, window, { passive: false });
    useLayoutEffect(() => onScroll(), [isLoading]);
    useLayoutEffect(() => {
        // console.log(whereAmI);
        if (whereAmI === "in") inViewportFunc();
        else if (whereAmI === "out") outViewportFunc();
    }, [whereAmI]);

    /**
     *  Functions depending on position
     */
    const inViewportFunc = () => {
        if (!containerDiv.current) return;
        if (cssClassName) containerDiv.current.className = cssClassName + " in";
        if (inViewportFunction) inViewportFunction();
    };
    const outViewportFunc = () => {
        if (!containerDiv.current) return;
        if (cssClassName) containerDiv.current.className = cssClassName + " out";
        if (outViewportFunction) outViewportFunction();
    };

    /**
     * RETURN
     */
    return (
        <StyledContainerDiv ref={containerDiv}>
            <div className="overflowZone">{children}</div>
        </StyledContainerDiv>
    );
};
