import { useContext, useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import useEventListener from "@use-it/event-listener";

import { viaTheme, PxaScrollBar, GlobalContext, HeaderLogo } from "dir";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------
const StyledMainLayout = styled.div`
    ${({ storybook, isSafeSize, ...props }) => {
        return css`
            position: relative;
            display: flex;
            min-width: min-content;
            min-height: 100%;
            flex-direction: column;
            background-color: ${storybook ? "tab" : false};
            box-sizing: border-box;

            & > div {
            }

            & > div[aria-label="StyledHeader"] {
                z-index: 1000;
                /* padding-top: 50px; */
                position: ${isSafeSize ? "fixed" : "relative"};
                left: 0px;
                width: calc(100vw - 30px);
                /* min-height: 150px; */
                box-sizing: border-box;
                ${viaTheme(props, "padding-left", [20, 40, 50, 50, 75])};
                ${viaTheme(props, "min-height", [100, 100, 100, 100, 150])};
                ${viaTheme(props, "padding-top", [35, 35, 50, 50, 50])};
            }
            & > div[aria-label="StyledContext"] {
                z-index: 1;
                width: max-content;
                min-width: 100%;
                display: flex;
                flex-direction: ${isSafeSize ? "row" : "column"};
                flex: 2 0 auto;
                flex-wrap: nowrap;
                box-sizing: border-box;

                & > div:first-child {
                    ${viaTheme(props, "padding-left", [20, 40, 80, 100, 200])};
                }
            }

            & > div[aria-label="StyledFooter"] {
                z-index: 1000;
                position: fixed;
                bottom: 0px;
                left: 0px;
                width: 100vw;
                min-height: 80px;
                box-sizing: border-box;
                ${viaTheme(props, "padding-left", [20, 50, 50, 50, 75])};
            }
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const MainLayout = ({ storybook = false, children, ...props }) => {
    const { isSafeSize, WINW, WINH } = useContext(GlobalContext);
    const contextArea = useRef();

    /**
     * calculate children's width, left, top values for scrollbar
     * has to work after DOM is ready
     */
    const [sizes, setSizes] = useState([]);
    const getNewSizes = () => {
        if (!contextArea.current) return;

        let newSizes = [];
        for (const el of contextArea.current.children) {
            const name = el.ariaLabel || "noName";
            const rect = el.getBoundingClientRect();
            newSizes.push({
                name: name,
                w: rect.width,
                l: rect.left,
                t: rect.top,
            });
        }
        setSizes(newSizes);
    };
    useEventListener("load", () => getNewSizes());
    useEventListener("scroll", () => getNewSizes());
    useEffect(() => getNewSizes(), [WINW, WINH]);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <StyledMainLayout aria-label="MainLayout" isSafeSize={isSafeSize} storybook={storybook}>
            <div aria-label="StyledHeader">{storybook ? "Header" : <HeaderLogo />}</div>
            <div aria-label="StyledContext" ref={contextArea}>
                {children}
            </div>
            {!isSafeSize ? null : (
                <div aria-label="StyledFooter">
                    {storybook ? "Footer" : <PxaScrollBar sizes={sizes} />}
                </div>
            )}
        </StyledMainLayout>
    );
};

//-------------------------------------------------------------
// 		PROP TYPES
//-------------------------------------------------------------

MainLayout.propTypes = {
    storybook: PropTypes.bool,
};
