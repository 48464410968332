import { theme } from "dir";
const defaultUnit = "px";

export const findColor = (color) => {
    return color ? theme?.colors[color] || color || "" : "";
};

export const findInSource = (source = {}, val = "", props = {}) => {
    let result;
    if (props) {
        Object.keys(props).forEach((propKey) => {
            if (source[propKey]) {
                result = source[propKey];
            }
        });
    }
    return result ? result : source[val] ? source[val] : source.default ? source.default : "";
};

export const findUnit = (val) => {
    return !val || isNaN(val) ? val : val + defaultUnit;
};

export const findMargin = (val) => {
    if (!val || (typeof val === "object" && Object.keys(val).length === 0)) {
        return null;
    }
    if (!isNaN(val)) {
        return val + defaultUnit;
    }
    let result = "";
    val.split(" ").forEach((el, key) => {
        if (key !== 0) {
            result = result + " ";
        }
        result = result + (!isNaN(el) ? findUnit(el) : el);
    });
    return result;
};
