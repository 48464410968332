import { createContext, useState, useEffect, useCallback, useLayoutEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { DateTime } from "luxon";
import { defineWindowWidth, defineWindowHeight, defineBrowser, defineCurrentBreakpoint } from "dir";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyles } from "./Theme";

export const GlobalContext = createContext();

const GlobalStore = (props) => {
    /**
     * Overall Context
     */
    const history = useHistory();
    const [pageName, setPageName] = useState(null);
    const [pageTitle, setPageTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const styledCursor = useRef(false);

    /**
     * Define Client Side Props
     */
    const [WINW, setWINW] = useState(defineWindowWidth());
    const [WINH, setWINH] = useState(defineWindowHeight());
    const BROWSER = defineBrowser();
    const colors = theme.colors;
    const breakpoints = theme.breakpoints;
    const [breakpoint, setBreakpoint] = useState(
        defineCurrentBreakpoint(breakpoints, WINW).breakpointName,
    );
    const [bpOrder, setBpOrder] = useState(defineCurrentBreakpoint(breakpoints, WINW).i);
    theme.bpOrder = bpOrder;
    const [isSafeSize, setIsSafeSize] = useState(true);

    /**
     * Resize throttle
     */
    useLayoutEffect(() => {
        const resizeBlocker = _.debounce(() => {
            const newWINW = defineWindowWidth();
            const newWINH = defineWindowHeight();
            const currentBreakpointValues = defineCurrentBreakpoint(breakpoints, newWINW);
            if (WINW !== newWINW) {
                setWINW(newWINW);
                setBreakpoint(currentBreakpointValues.breakpointName);
                setBpOrder(currentBreakpointValues.i);
            }
            if (WINH !== newWINH) {
                setWINH(newWINH);
            }
        }, 500);

        window.addEventListener("resize", resizeBlocker);
    }, []);
    useEffect(() => {
        const newIsSafeSize = WINW > 991 && WINH > 500 ? true : false;
        if (newIsSafeSize !== isSafeSize) {
            setIsSafeSize(newIsSafeSize);
        }
    }, [WINW, WINH]);

    /**
     * Luxon settings
     */
    const today = DateTime.local();
    const dateToScreen = "dd LLL yyyy"; // today.toFormat(dateToScreen)
    const dateToAPI = "yyyyMMdd"; // today.toFormat(dateToAPI)

    /**
     * GO TO
     * @description pushs "/val" to history
     */
    const goTo = (val) => {
        history.push("/" + val);
    };

    //-------------------------------------------------------------
    // 		EFFECTS
    //-------------------------------------------------------------

    /**
     * Change Page Title
     */
    useEffect(() => {
        if (!pageTitle) {
            document.title = "thePXA Creative";
        } else {
            document.title = pageTitle;
        }
    }, [pageTitle]);

    /**
     * Entering A New Page Default Funcks for All pages
     */
    const newPage = (obj) => {
        setIsLoading(true);
        setPageName(obj.pageName || "newPage");
        setPageTitle(obj.title || "");
    };

    /**
     * DISABLE ISLOADING WITH DELAY
     * @description covers CSS transition faults.
     */
    const disableIsLoadingWithDelay = useCallback(
        _.debounce(async (obj) => {
            setIsLoading(false);
        }, 1000), //500
        [],
    );

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <GlobalContext.Provider
                value={{
                    WINW,
                    WINH,
                    BROWSER,
                    isSafeSize,
                    breakpoints,
                    breakpoint,
                    bpOrder,
                    colors,
                    goTo,

                    isLoading,
                    setIsLoading,
                    pageName,
                    setPageName,
                    pageTitle,
                    setPageTitle,
                    styledCursor,

                    disableIsLoadingWithDelay,
                    dateToScreen,
                    dateToAPI,
                    today,
                    newPage,
                    history,
                }}
            >
                {props.children}
            </GlobalContext.Provider>
        </ThemeProvider>
    );
};

export default GlobalStore;
