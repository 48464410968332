import { useContext } from "react";
import styled, { css } from "styled-components";
import { GlobalContext, MyLottiePlayer } from "dir";
import loadingTextAnim from "./loadingTextAnim.json";
import backdrop from "./loadingBack.svg";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const StyledDiv = styled.div`
    ${(props) => {
        return css`
            z-index: 10000;
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100vw;
            height: ${props.isLoading ? "100vh" : "0px"};
            pointer-events: ${props.isLoading ? "auto" : "none"};
            overflow: hidden;
            transition: ${props.isLoading ? "all 0ms ease-in-out" : "all 250ms ease-in-out"};
            background-color: red;
            left: 0px;
            top: 0px;
            /* opacity: ${props.isLoading ? 1 : 0}; */
        `;
    }}
`;
const BackgroundImage = styled.div`
    ${(props) => {
        return css`
            z-index: 1;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            background: #ff0000 url(${backdrop}) no-repeat center center;
            background-size: cover;

            /* & > img {
                height: ${props.isSafeSize ? false : "100vh"};
            } */
        `;
    }}
`;
const LottieDiv = styled.div`
    ${(props) => {
        return css`
            z-index: 2;
            position: relative;
            color: white;
        `;
    }}
`;

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const Loading = (props) => {
    const { isLoading, isSafeSize } = useContext(GlobalContext);

    /**
     * Return
     */
    return (
        <StyledDiv isLoading={isLoading}>
            <BackgroundImage isSafeSize={isSafeSize} />
            <LottieDiv>
                {!isLoading ? null : (
                    <MyLottiePlayer
                        name="imajUstu"
                        width="150px"
                        src={loadingTextAnim}
                        speed="2"
                        autoplay={true}
                        hover={false}
                        loop={false}
                    />
                )}
            </LottieDiv>
        </StyledDiv>
    );
};
