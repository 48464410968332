import { Route, Switch } from "react-router-dom";
import { Home } from "dir";

import { Sneakers } from "pages/Works/Sneakers/Sneakers";

/**
 * Export
 */
export default (
    <Route>
        <Switch>
            <Route exact path={["/home", "/"]} component={Home} />
            <Route exact path={["/Sneakers"]} component={Sneakers} />

            {/* default page */}
            <Route component={Home} />
        </Switch>
    </Route>
);
