export const defineWindowWidth = () => {
    return window.innerWidth || document.documentElement.clientWidth || 0;
};
export const defineWindowHeight = () => {
    return window.innerHeight || document.documentElement.clientHeight || 0;
};

export const defineBrowser = () => {
    let browser;
    if (
        (!!window.opr && !!window.opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0
    ) {
        browser = "opera";
    } else if (typeof InstallTrigger !== "undefined") {
        browser = "firefox";
    } else if (
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
        })(!window["safari"] || (typeof safari !== "undefined" && window.safari.pushNotification))
    ) {
        browser = "safari";
    } else if (/*@cc_on!@*/ false || !!document.documentMode) {
        if (!!window.StyleMedia) {
            browser = "ie";
        } else {
            browser = "oldEdge";
        }
    } else if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) {
        if (navigator.userAgent.indexOf("Edg") !== -1) {
            browser = "edge";
        } else {
            browser = "chrome";
        }
    }
    return browser || "undefined";
};
