import { pushArrayAsSorted } from "dir";

export const defineCurrentBreakpoint = (breakpoints = {}, WINW = 0) => {
    let switchedObj = {};
    let valuesToSort = [];
    for (const [key, value] of Object.entries(breakpoints)) {
        switchedObj[value] = key;
        valuesToSort.push(value);
    }
    const i = pushArrayAsSorted(valuesToSort, WINW).i;
    const px = valuesToSort[i];
    const breakpointName = switchedObj[px];
    return {
        i: i + 1,
        px: px,
        breakpointName: breakpointName,
    };
};
