import styled, { css } from "styled-components";
import { viaTheme, findInSource, findColor } from "dir";
import PropTypes from "prop-types";

//-------------------------------------------------------------
// 		STYLES
//-------------------------------------------------------------

const H1 = styled.h1`
    ${(props) => {
        return css`
            color: ${props.color || props.theme.colors.brand};
            font-weight: ${props.weight || 900};
            display: block;
            ${viaTheme(props, "font-size", [24, 26, 28, 30, 32])};
            margin: 20px 0px 20px 0px;
            /* transform: rotate(90deg); */
        `;
    }}
`;
const H2 = styled.h2`
    ${(props) => {
        return css`
            color: ${props.color};
            font-weight: ${props.weight || 400};
            display: block;
            ${viaTheme(props, "font-size", [22, 24, 26, 28, 30])};
            margin: 20px 0px 20px 0px;
        `;
    }}
`;
const H3 = styled.h3`
    ${(props) => {
        return css`
            color: ${props.color || props.theme.colors.default};
            display: block;
            font-family: "vdl-logojr", sans-serif;
            font-weight: 400;
            ${viaTheme(props, "font-size", [20, 20, 24, 26, 28])};
            max-width: 400px;
            /* margin: -14px 0px 20px 0px; */
            ${viaTheme(
                props,
                "margin",
                [
                    "-10px 0px 10px 0px",
                    "-10px 0px 10px 0px",
                    "-10px 0px 20px 0px",
                    "-10px 0px 20px 0px",
                    "-10px 0px 20px 0px",
                ],
                "",
            )};
        `;
    }}
`;
const H4 = styled.h4`
    ${(props) => {
        return css`
            color: ${props.color};
            font-weight: ${props.weight || 400};
            display: block;
            ${viaTheme(props, "font-size", [18, 20, 22, 24, 26])};
            margin: 20px 0px 20px 0px;
        `;
    }}
`;
const H5 = styled.h5`
    ${(props) => {
        return css`
            color: ${props.color};
            font-weight: ${props.weight || 700};
            display: block;
            ${viaTheme(props, "font-size", [14, 14, 14, 15, 16])};
            margin: 10px 0px 10px 0px;
        `;
    }}
`;
const H6 = styled.h6`
    ${(props) => {
        return css`
            color: ${props.color};
            font-weight: ${props.weight || 400};
            display: block;
            ${viaTheme(props, "font-size", [14, 16, 18, 20, 22])};
            margin: 20px 0px 20px 0px;
        `;
    }}
`;

//-------------------------------------------------------------
// 		STYLES and PROP VALUES
//-------------------------------------------------------------

const patterns = {
    default: styled.span`
        ${(props) => {
            return css`
                color: ${props.theme.colors.softGrey};
                font-weight: ${props.weight || 400};
                display: ${props.display || "inline-block"};
                ${viaTheme(props, "font-size", [13, 13, 13, 14, 14])};
                /* max-width: 300px; */
                ${viaTheme(props, "max-width", [300, 400, 500, 300, 300])};
                margin: 20px 0px;
            `;
        }}
    `,

    small: styled.span`
        ${(props) => {
            return css`
                color: ${props.color};
                font-weight: ${props.weight || 400};
                display: ${props.display || "inline-block"};
                ${viaTheme(props, "font-size", [12, 12, 12, 13, 14])};
            `;
        }}
    `,

    p: styled.p`
        ${(props) => {
            return css`
                color: ${props.color};
                font-weight: ${props.weight || 400};
                display: ${props.display || "block"};
                ${viaTheme(props, "font-size", [14, 14, 14, 15, 16])};
                line-height: 2;
                max-width: 600px;
            `;
        }}
    `,

    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H5,
    h6: H6,
    pageTitle: H1,
    seperator: H3,
    boxTitle: H5,
};

const weights = {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
};

//-------------------------------------------------------------
// 		MAIN COMPONENT
//-------------------------------------------------------------

export const Text = ({ pattern, weight, ...props }) => {
    const Comp = findInSource(patterns, pattern);

    return (
        <Comp
            color={findColor(props.color)}
            weight={findInSource(weights, weight)}
            display={props.display}
            {...props}
        >
            {props.children}
        </Comp>
    );
};

//-------------------------------------------------------------
// 		PROP TYPES
//-------------------------------------------------------------

Text.propTypes = {
    pattern: PropTypes.oneOf(Object.keys(patterns)),
    color: PropTypes.string,
    display: PropTypes.string,
    weight: PropTypes.oneOf(Object.keys(weights)),
};
